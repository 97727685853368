import React, { useEffect, useState, useRef } from "react";
import { chatService } from "./../../services/chat.service";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { ItemConversation } from "./itemConversation";
import InfiniteScroll from "react-infinite-scroll-component";

export function ChatConversations({
  currentChat,
  newMessageReceived,
  notification,
  toggle,
  openChat,
}) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [dateTime, setDateTime] = useState(null);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const [blockedChat, setBlockedChat] = useState({});
  const inputElementRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (Object.keys(currentChat).length > 0) {
      chatService.getMessages(currentChat.contactId).then((result) => {
        setMessages(result);
        if (result.length > 0) {
          setDateTime(result.at(-1).date);
          chatService.updateRead(currentChat.contactId);
        } else setDisabledHasMore(true);
      });
    }
  }, [currentChat]);

  useEffect(() => {
    if (Object.keys(newMessageReceived).length > 0 && openChat) {
      setMessages([newMessageReceived, ...messages]);
      if (!newMessageReceived.isSender)
        chatService.updateRead(currentChat.contactId);
    }
  }, [newMessageReceived, openChat]);

  useEffect(() => {
    if (notification != "" && !notification.includes("blockedContact")) {
      let messagesUnread = messages.map((m) => {
        if (m.messageId == notification) m.status = "Read";
        return m;
      });
      setMessages(messagesUnread);
    } else contactStatus();
  }, [notification]);

  const contactStatus = () => {
    chatService.contactStatus(currentChat.contactId).then((result) => {
      setBlockedChat(result);
    });
  };

  const timelineRef = useRef();
  const onSubmit = (e) => {
    e.preventDefault();
    if (newMessage != "") {
      buttonRef.current.disabled = true;
      timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
      const chatMessage = {
        message: newMessage,
        receiver: {
          id: currentChat.contactId,
          name: currentChat.name,
          image: currentChat.image,
          identityFlagName: currentChat.identityFlagName,
        },
      };
      chatService.sendMessage(chatMessage).then(() => {
        setNewMessage("");
        buttonRef.current.disabled = false;
        inputElementRef.current.focus();
      });
    }
  };

  const loadMore = () => {
    chatService.getMessages(currentChat.contactId, dateTime).then((result) => {
      if (result.length > 0) {
        setMessages([...messages, ...result]);
        setDateTime(result.at(-1).date);
      }
      setDisabledHasMore(true);
    });
  };

  const lockAndUnlock = async () => {
    if (blockedChat.byMe)
      await chatService.contactUnlocked(currentChat.contactId);
    else await chatService.blockedContact(currentChat.contactId);

    contactStatus();
  };

  const handleNewMessage = (event) => {
    buttonRef.current.disabled = !event.target.value;
    setNewMessage(event.target.value);
  };

  return (
    <div className="sections_chat active_sec wrapper_chat_conversations">
      <div className="sec_info_chats">
        <div className="to_back" onClick={() => toggle("1")}>
          <i className="fas fa-chevron-left"></i>
        </div>
        <AvatarChats
          img={currentChat.image}
          flagClass={currentChat.identityFlagName}
        />
        <div className="box_info">
          <TtlsChats title={currentChat.name} />
          {blockedChat && blockedChat.byMe == true && (
            <p className="m-0 text-danger">
              <small>Usuario bloqueado</small>
            </p>
          )}
        </div>

        <div className="btn-group">
          <span
            className="btn_ico_more dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
          </span>
          <div className="dropdown-menu box_drop_opt dropdown-menu-right">
            {/* <button className="dropdown-item" type="button">
              <span>Descargar conversación</span>
              <span>
                <ion-icon name="download-outline"></ion-icon>
              </span>
            </button>
            <div className="dropdown-divider"></div>
            <button className="dropdown-item" type="button">
              <span>Eliminar</span>
              <span>
                <ion-icon name="trash-outline"></ion-icon>
              </span>
            </button> */}

            <button
              className="dropdown-item block_user"
              type="button"
              onClick={lockAndUnlock}
            >
              {blockedChat && blockedChat.byMe == true ? (
                <span>Desbloquear usuario</span>
              ) : (
                <span>Bloquear usuario</span>
              )}
              <span>
                <ion-icon name="close-circle-outline"></ion-icon>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        className="sec_conten_conversations p-3"
        id="scrollableDiv"
        ref={timelineRef}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={loadMore}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          hasMore={true && !disabledHasMore}
          loader={<h4>Cargando...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((item) => {
            return item.isSender ? (
              <ItemConversation
                key={item.messageId}
                source="out"
                author="Tu."
                checkMnj={item.status == "Read"}
                dateTime={item.date}
                chatMnj={item.message}
              />
            ) : (
              <ItemConversation
                key={item.messageId}
                source="in"
                author={item.senderName}
                checkMnj={true}
                dateTime={item.date}
                chatMnj={item.message}
              />
            );
          })}
        </InfiniteScroll>
      </div>

      <div className="sec_content_typing">
        {!currentChat.readOnly && blockedChat && blockedChat.locked == false && (
          <form onSubmit={onSubmit}>
            <div className="form-group box_intro_text">
              <textarea
                className="form-control "
                id="message"
                placeholder="Escribe un mensaje aquí"
                value={newMessage}
                onChange={handleNewMessage}
                ref={inputElementRef}
              ></textarea>
              <button
                type="submit"
                className="btn"
                ref={buttonRef}
                disabled={true}
              >
                <i className="fas fa-paper-plane"></i>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
