import React, { Fragment, useState, useEffect }  from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./sideAdvertising.scss";
import { useCompanyHoliday } from "../../services/companyHoliday.service";

export function SideAdvertising() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    arrows: true,
    adaptiveHeight: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getCompanyHolidays();
  }, []);

  const [sliderItems, setSliderItems] = useState([]);
  const [video, setVideo] = useState({ type: 0, src: "" })

  async function getCompanyHolidays() {
      useCompanyHoliday.getCompanyHolidayFront(3).then((result) => {
          if (result) {
              var temp = [];
              result.map((ch) => {
                  temp.push({
                      key: ch.companyHolidayId.toString(),
                      file: ch.imagePath,
                      description: ch.description,
                      isVideo: ch.isVideo,
                      link: ch.iframe
                  });
              });
              setSliderItems(temp);
          }
      });
  }

  return (
    <div className="wrapper_side_advertising">
    <Slider {...settings}>
      {sliderItems.map(item => (
        <a target="_blank" href={ item.link } className="item_advertising" key={item.key}>
          <img
            src={item.file}
            alt={item.description}
            title={item.description}
          />
          <h6 className="t_pub text fnt_medium">
            {item.description}
          </h6>
        </a>
      ))}
      </Slider>
    </div>
  );
}
