import { callApi, callApiNoBlocking } from "./service";

export const backendService = {
  widgets,
  userProfile,
  userSettings,
  userProfileActivity,
  GetNotificationData,
  CountNotificationUser,
  getCompanyCustomize,
};

async function widgets() {
  return callApiNoBlocking("/Home/Widgets", "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return null;
    }
  });
}

async function CountNotificationUser() {
  return callApi("/Home/GetNotificationCount", "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return null;
    }
  });
}

async function GetNotificationData() {
  return callApiNoBlocking("/Home/GetNotification", "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return null;
    }
  });
}

/*
Get user profile info
 */

async function userProfile() {
  return callApi(
    `/BackEnd/Profile?UserId=${localStorage["userId"]}`,
    "GET"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return null;
    }
  });
}

async function userSettings() {
  return callApi("/Home/UserSettings", "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return {};
    }
  });
}

async function userProfileActivity(active) {
  return callApi("/BackOffice/Attendee/GetUserActivity/" + active, "GET").then(
    (result) => {
      if (result.responseCode === "00") {
        return result.data;
      } else {
        return {};
      }
    }
  );
}

async function getCompanyCustomize(companyId) {
  return callApi(
    "/BackEnd/CompanyCustomize/GetByCompanyId/" + companyId,
    "GET",
    undefined,
    { SkipValidationToken: true }
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      return {};
    }
  });
}
