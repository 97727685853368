import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ItemSocial(props) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={renderTooltip(props.socialIcoName)} >
      <a href={props.socialUrl} target="_blank" className="a_ico_social">
        <ion-icon name={props.socialIco}></ion-icon>
      </a>
    </OverlayTrigger>
  );
}