import React, { Fragment, useState } from 'react'
import InboxMessages from './inboxMessages'
import SentMessages from './sentMessages'
import ArchivedMessages from './archivedMessages'
import ComposeNewMessage from './composeNewMessage'
import ConfigurePrivileges from './configurePrivileges'
import DraftMessages from './draftMessages'
import { useSelector } from 'react-redux';

import './messages.scss'

export function Messages(props) {
  const roleName = useSelector(state => state.userSettings.roleName)
  const [isActive, setIsActive] = useState(1)
  const [draft, setDraft] = useState({})

    return (
        <section className="wrapper_messages padd">
            <div className="template_messages ">
                <div className="box_group_messages">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button className={"btns " + (isActive === 1 ? "active" : "")} onClick={() => setIsActive(1)}>Bandeja de entrada</button>
                        <button className={"btns " + (isActive === 2 ? "active" : "")} onClick={() => setIsActive(2)}>Enviados</button>
                        <button className={"btns " + (isActive === 3 ? "active" : "")} onClick={() => setIsActive(3)}>Archivados</button>
                        {roleName == "Recursos Humanos" &&
                            <Fragment>
                            <button className={"btns " + (isActive === 4 ? "active" : "")} onClick={() => setIsActive(4)}>Borradores</button>
                            <button className={"btns " + (isActive === 6 ? "active" : "")}  onClick={() => setIsActive(6)}>Configuración</button>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="content_messages">
                    {isActive === 1 && <InboxMessages setIsActive={setIsActive} setDraft={setDraft} />}
                    {isActive === 2 && <SentMessages setIsActive={setIsActive} setDraft={setDraft} />}
                    {isActive === 3 && <ArchivedMessages setIsActive={setIsActive} setDraft={setDraft} />}
                    {isActive === 4 && <DraftMessages setIsActive={setIsActive} setDraft={setDraft} />}
                    {isActive === 5 && <ComposeNewMessage setIsActive={setIsActive} draft={draft} setDraft={setDraft} />}
                    {isActive === 6 && <ConfigurePrivileges />}
                </div>
            </div>
        </section>
    )
}