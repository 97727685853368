import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";

export function ItemMorePost({ filter, getPost }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const anio = new Date().getFullYear();
    if (anio == filter.year) setIsOpen(true);
  }, []);

  return (
    <div className="item_more_post">
      <div onClick={toggle} className="cursor mb-2">
        <i className="far fa-plus-square"></i> {filter.year}
      </div>
      <Collapse className="box_collapse" isOpen={isOpen}>
        <ul className="box_ul_month">
          {filter.months.map((item) => (
            <li
              onClick={() => getPost(new Date(item.filterMonthDate))}
              key={item.filterMonthDate}
            >
              <span className="dots">{item.month.substring(0, 1)}</span>
              <span className="name_mes">{item.month}</span>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
}
