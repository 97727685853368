import React from 'react'
import { Link } from 'react-router-dom';

import './rewards.scss'

export function HeadRewards({ reward }) {
    return (
        <div className="wrapper_head_rewards padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium">Recompensas</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-7">
                    <h6 className="text fnt_medium text-muted">Estado de cuentas</h6>
                    <div className="content_statistics">
                        <Link to="/rewards" className="item_statistics shadows large item25">
                            <div className="statistics">
                                <h6 className="text fnt_medium">Saldo</h6>
                                <span >
                                    <h3 className="text fnt_medium">{reward}</h3>
                                    <h6 className="text fnt_light">Puntos</h6>
                                </span>
                            </div>
                        </Link>
                        {/*<div className="item_statistics shadows item25">
                              <div className="statistics">
                                <h6 className="text fnt_medium">Vencimiento</h6>
                                <span >
                                  <h6 className="text fnt_light">12 de septiembre de 2020</h6>
                                </span>
                              </div>
                           </div>*/}

                        <Link
                            to="/redemption-history"
                            className="item_statistics shadows item25"
                        >
                            <div className="statistics">
                                <h6 className="text fnt_medium">Historial de canje</h6>
                                <span >
                                    <h6 className="text fnt_light">Ver más</h6>
                                </span>
                            </div>

                        </Link>

                        <Link
                            to="/account-status"
                            className="item_statistics shadows item25"
                        >
                            <div className="statistics">
                                <h6 className="text fnt_medium">Estado de cuenta</h6>
                                <span >
                                    <h6 className="text fnt_light">Ver más</h6>
                                </span>
                            </div>
                        </Link>

                    </div>
                </div>
                <div className="col-lg-5">
                    <h6 className="text fnt_medium text-muted">Gana puntos</h6>
                    <div className="content_statistics ">
                        <div className="item_statistics shadows large item25">
                            <div className="statistics unique">
                                <div className="ico_unique">
                                    <span><i className="fas fa-award"></i></span>
                                </div>
                                <div>
                                    <p>&middot; Haciendo Check-In diariamente</p>
                                    <p>&middot; Contestando encuestas</p>
                                    <p>&middot; Abriendo las opciones de contenido de Cursos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
        </div>

    )
}