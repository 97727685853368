import React, { useEffect, useState } from "react";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { ItemJobPublished } from "./itemJobPublished";
import { JobBankService } from "../../services/jobBank.service";
import { TtlsJobBank } from "./ttlsJobBank";
import "./jobBank.scss";

export function JobBank() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("Fecha");

  useEffect(() => {
    searchPage();
  }, []);

  const searchPage = (page = 1, order = "Fecha", occupationFilter = "", locationFilter = "") => {
    setOrder(order);
    JobBankService.getJobAll(page, 8, order, occupationFilter, locationFilter).then((result) => {
      if(!result) return;
      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setCurrentPage(page);
      setTotalPages(result.totalPages);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li className={"page-item" + (page === index ? " active" : "")} key={index}>
            <a className="page-link" onClick={() => searchPage(index, order)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows)
    });
  };

  const returnPreviousPage = () => {
    if (currentPage > 1) {
      searchPage(currentPage - 1);
    }
  };

  const advanceNextPage = () => {
    if (currentPage < totalPages) {
      searchPage(currentPage + 1);
    }
  };

  const searchByFilters = () => {
    let occupationFilter = document.getElementById("occupationFilter").value;
    let locationFilter = document.getElementById("locationFilter").value;

    searchPage(1, order, occupationFilter, locationFilter);
  }


  return (
    <section className="wrapper_jobbank padd">
      <TtlsJobBank tituloInJobs="Bolsa de trabajo"/>
      <section className="contain_gral_jobbank">
        <div className="sec_catalog_listjobs">
          <div className="box_searching_jobs">
              <div className="row align-items-end">
                <div className="col-md-6 col-lg-5 boxsearch_item">
                  <div className="form-group">
                    <label htmlFor="occupationFilter">
                      <ion-icon name="briefcase"></ion-icon> Cargo o área
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="occupationFilter"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 boxsearch_item">
                  <div className="form-group">
                    <label htmlFor="locationFilter">
                      <ion-icon name="location-outline"></ion-icon> Lugar
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locationFilter"
                    />
                  </div>
                </div>
                <div className="col-md-2 boxsearch_item">
                  <button className="btn secundary" onClick={searchByFilters}>Buscar</button>
                </div>
              </div>
          </div>
          <div className="cont_box_items_jobs">
            <div className="options_order">
              <div>
                <strong>{totalCount}</strong> empleos
              </div>
              <div className="d-flex align-items-center">
                <span className="w-100">Ordenar por </span>
                <select className="form-control form-control-sm" onChange={(e) => searchPage(1, e.target.value)}>
                  <option value="Fecha">Fecha</option>
                  <option value="Salario">Salario</option>
                </select>
              </div>
            </div>
            <div className="box_items_jobs row">
              {jobs.map((job, index) => (
                <ItemJobPublished key={job.jobId} job={job} index={index} misEmpl={false} />
              ))}
            </div>
            <div className="mt-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" onClick={returnPreviousPage} aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {pages}
                  <li className="page-item">
                    <a className="page-link" onClick={advanceNextPage} aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <AsideCategoriesJobs />
      </section>
    </section>
  );
}
