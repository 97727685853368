import React, { Fragment, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import C_H from "../../assets/imgs/company_Holiday.jpg";
import { Modal } from "reactstrap";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import "./sliderHome.scss";

export function SliderHome() {
  const [sliderItems, setSliderItems] = useState([]);
  const [video, setVideo] = useState({ type: 0, src: "" });

  useEffect(() => {
    getCompanyHolidays();
  }, []);

  async function getCompanyHolidays() {
    useCompanyHoliday.getCompanyHolidayFront(2).then((result) => {
      if (result) {
        var temp = [];
        result.map((ch) => {
          temp.push({
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            video: {
              type: ch.iframe ? 1 : 2,
              src: ch.iframe ? ch.iframe : ch.videoPath,
            },
          });
        });
        setSliderItems(temp);
      }
    });
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    adaptiveHeight: true,
    pauseOnHover: true,
  };

	const vidRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = (video) => {
		setVideo(video);
    setModal(!modal);
		vidRef.current.play();
		
  };



  return (
    <Fragment>
      <div className="wrapper_slide_home">
        {sliderItems && sliderItems.length > 0 ? (
          <Slider {...settings}>
            {sliderItems.map((item) => (
              <div key={item.key}>
                {item.isVideo ? (
                  <div className="box_video_poster">
                    <span
                      className="ico_play trans"
                      onClick={() => toggle(item.video)}
                    >
                      <i className="fas fa-play"></i>
                    </span>
                    <img
                      className="item_img_slider"
                      src={item.file}
                      alt={item.description}
                    />
                  </div>
                ) : (
                  item.video.src ?
                  <a href={item.video.src}>
                    <img
                      className="item_img_slider"
                      src={item.file}
                      alt={item.description}
                    />
                  </a>
                  :
                  <img
                    className="item_img_slider"
                    src={item.file}
                    alt={item.description}
                  />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <img
            className="item_img_slider"
            src={C_H}
            alt="Aviso"
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          />
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} className="wrapper_modal_cap">
        <div>
          <div className="toolbar_opt_modal">
            <span className="btns_modal text fnt_bold" onClick={toggle}>
              <i className="far fa-window-close"></i> <span>CERRAR</span>
            </span>
          </div>
          {
            {
              1: (
                <div
                  dangerouslySetInnerHTML={{ __html: video.src }}
                  style={{ display: "contents" }}
                />
              ),
              2: (
                <video  ref={vidRef} className="box_videos" controls preload autoPlay>
                  <source src={video.src} type="video/mp4" />
                </video>
              ),
            }[video.type]
          }

        </div>
      </Modal>
    </Fragment>
  );
}
