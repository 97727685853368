import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { JobBankService } from "../../services/jobBank.service";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from 'react-select/creatable';
import { ValidacionEmail } from "../../utils/ValidacionMail"
import Swal from "sweetalert2";

export function FormJob({datos, setDatos, toggleView, setJob}) {
      const [editorState, setEditorState] = useState();
      const [selectedOption, setSelectedOption] = useState([]);
      const [options, setOptions] = useState([]);
    
      useEffect(() => {
        JobBankService.getCategories().then((result) => {
          if(!result) return;
          setOptions(result.map(x => ({ value: x.jobCategoryId, label: x.name })));
        });
      }, []);

      function updateImage(e) {
        var file = e.target.files[0];
        inputs.imagen= file;
      }
    
      const onSave = () => {
        inputs.descripcion = editorState;
        inputs.categoria = selectedOption;
        let error = ValidacionEmail(inputs.mail);
        if (error === false) {
            Swal.fire({ text: "Correo incorrecto, por favor revise.", icon: "error" });
            return;
        }
        if(inputs.categoria.length === 0){
            Swal.fire({ text: "Seleccion al menos una categoría", icon: "error" });
            return;
        }
        if(inputs.descripcion === ""){
            Swal.fire({ text: "Ingrese una descripción", icon: "error" });
            return;
        }
        insertJob()
      };
    
      async function insertJob() {
        
        //Agarrar datos del input y ponerlo en este formData

        let formData = new FormData();
        formData.append("CompanyId", process.env.REACT_APP_COMPANY);
        formData.append("Title", inputs.title);

        //Intentar agarrar lo que pone el editor y ponerlo un string

        formData.append("Description", inputs.descripcion);
        formData.append("Currency", "MXN");
        formData.append("Salary", inputs.infoSalarial);
        formData.append("Location", inputs.location);
        formData.append("Direction", inputs.ubicacion);
        formData.append("CompanyName", inputs.titleCompany);

        //En CompanyImage debería ir el logo de la empresa

        formData.append("CompanyImage", inputs.imagen);
        formData.append("PhoneNumber", inputs.phoneNumber);
        formData.append("Email", inputs.mail);
    
        selectedOption.forEach((element, index) => {
          formData.append(`JobCategory[${index}].JobCategoryId`, element?.__isNew__ ? 0 : element.value);
          formData.append(`JobCategory[${index}].Name`, element.label);
        });

        JobBankService.insertJob(formData).then((result) => {
        setJob(result);
        Swal.fire({text: "Se ha registrado la oferta de trabajo", icon: "success", showCloseButton: true, allowOutsideClick: false, allowEscapeKey: false})
          .then((result) => {
            toggleView();
          });
        //clean inputs

        setDatos({
          title: "",
          categoria: [],
          ubicacion: "",
          location: "",
          titleCompany: "",
          imagen: null,
          infoSalarial: "",
          descripcion: "",
          phoneNumber: "",
          mail: "",
        });
        setEditorState(EditorState.createEmpty());
        setSelectedOption([]);
        });
      }
    
      const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
        onSave,
        datos
      );
    
      const onEditorStateChange = (editorState) => {
        setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      };
    
      const getFileImage = async (url) => {
        console.log(url);
        let fileName = url.substring(0, url.lastIndexOf("."));
        let file = await fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], fileName, blob);
            return file;
          });
        console.log(file);
        return file;
      };

      return (
          
        <form onSubmit={handleSubmit}>
        <div className="bar_step_reg">
          <div className="box_item_step step_active">
            <span className="item_step">1</span>
          </div>
          <div className="box_item_step ">
            <span className="item_step">2</span>
          </div>
        </div>
        <div className="content_shadows_form">
          <div className="row mt-3">
            <div className="col-md-12">
              <h6 className="text fnt_medium mb-3">
                Información de la vacante
              </h6>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Título de la vacante</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Título de la vacante"
                  name="title"
                  required={true}
                  onChange={handleInputChange}
                  // onChange={(x) =>{console.log(x.target.value.outerHTML)}}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="categoria">
                  Categoría
                  {/* <small>
                    <i>(Opcional)</i>
                  </small> */}
                </label>
                <CreatableSelect
                  closeMenuOnSelect={false}
                  value={selectedOption}
                  required={true}
                  onChange={setSelectedOption}
                  placeholder="Selecciona una categoría"
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={options}
                  id="categoria"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="ubicacion">Dirección</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Ubicación"
                  name="ubicacion"
                  errormessage=""
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="location">Localidad</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Localidad"
                  name="location"
                  errormessage=""
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="titleCompany">Nombre de la empresa</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Nombre de la empresa"
                  name="titleCompany"
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="mail">Correo</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Correo electrónico"
                  name="mail"
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Número de contacto</label>
                <input
                  className="form-control form-control-sm"
                  placeholder="Número de teléfono"
                  name="phoneNumber"
                  maxLength={10}
                  minLength={10}
                  onKeyPress={(e) => {
                    // Solo se admiten números
                    e = e || window.event;
                    var charCode = e.keyCode || e.which;
                    var charStr = String.fromCharCode(charCode);
                    if(isNaN(charStr))
                    e.preventDefault();
                  }}
                  onChange={handleInputChange}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group isHidde">
                <label htmlFor="">Logo empresa</label>
                <input
                  type="file"
                  className=""
                  id="file-upload"
                  placeholder="Imagen destacada"
                  accept="image/*"
                  name="imagen"
                  onChange={(x) => updateImage(x)}
                  // required={true}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="row mt-3">
            <div className="col-md-12">
              <h6 className="text fnt_medium mb-3">Información salarial</h6>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Salario bruto</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text amount">
                      <input
                        type="radio"
                        name="exampleRadios"
                        aria-label="Radio button for following text input"
                      />
                    </div>
                    <span className="input-group-text amount">$</span>
                  </div>
                  <input
                    type="number"
                    min={0}
                    step="0.01"
                    // disabled={true}
                    className="form-control"
                    name="infoSalarial"
                    onChange={handleInputChange}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col-md-12">
              <h6 className="text fnt_medium mb-3">
                Descripción de la vacante
              </h6>
            </div>
            <div className="col-md-12">
              <Editor
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper__editor_text"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: [
                    "inline",
                    "colorPicker",
                    "blockType",
                    "fontSize",
                    // "fontFamily",
                    "list",
                    "textAlign",
                    // "image",
                    "link",
                    "remove",
                    "history",
                  ],
                  inline: {
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                    ],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4 text-center">
              <button className="btn primary" type="submit">
                Continuar
              </button>
            </div>
          </div>
        </div>
      </form>
      )
}