import React, { useEffect, useState } from 'react';
import { useJobRole } from '../../../services/jobRole.service';

export function SearchJobRole(props) {
    const [jobsData, setJobsData] = useState({ jobs: [] });
    const handleDelete = (data) => {
        useJobRole.deleteJobRole(data).then((result) => { getJobRoles(); });
    };

    useEffect(() => {
        getJobRoles();
    }, [props.refresh]);

    async function getJobRoles() {
        useJobRole.getJobRoles().then((result) => {
            if (result) {
                setJobsData({ jobs: result });
            }
        });
    }

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Nombre</th>
                        <th className="text fnt_medium min_width">Descripción</th>
                        <th className="text fnt_medium">Estatus</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobsData.jobs.map(job => (
                        <tr key={job.jobRoleId} >
                            <td>{job.name}</td>
                            <td>{job.description}</td>
                            <td>{job.active ? 'Activo' : 'Inactivo'}</td>
                            <td>
                                {job.active ?
                                    <span>
                                        <span className="icon_tables" onClick={() => props.onSelectJobRole(job)}>
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        <span className="icon_tables" onClick={() => handleDelete(job)}>
                                            <i className="far fa-trash-alt"></i>
                                        </span>
                                    </span>
                                    : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}