import React, { useState, useEffect } from 'react';
import { courseService } from '../../services/course.service';
import { storageService } from '../../services/storage.service';
import { useDispatch } from 'react-redux';



export function CourseAttachment(props) {
    const dispatch = useDispatch();
    const [localStorage, setlocalStorage] = useState([]);

    async function getAttachments() {

        if (props.CourseId != null && props.CourseId > 0) {
            courseService.getCourseFilesById(props.CourseId).then((result) => {
                if (result != null && result.files != null) {
                    setlocalStorage(result.files);
                    props.setCountStorageFiles(result.files.length)
                }
            });
        }
    }

    useEffect(() => {
        getAttachments();
    }, [props.CourseId]);

    const handleDelete = (event, data) => {

        storageService.deleteStorage({ EntityId: data.entityId, EntityTypeId: data.entityTypeId, Sequence: data.sequence }).then((result) => {
            setlocalStorage(
                localStorage.filter(function (storage) {
                    return storage.sequence !== data.sequence;
                })
            );
            props.setCountStorageFiles(localStorage.length)
        });
        event.stopPropagation();
    };

    return (
       
        <span >            
            {localStorage != null && localStorage.length > 0 ?
                <span className="files">
                    {localStorage.map(item => (
                        <span>
                            <a
                                className="item_file"
                                key={item.entityId + '-' + item.entityTypeId + '-' + item.sequence}
                                download={item.fileName} href={`data:application/octet-stream;base64,${item.file}`}
                            >

                                <span className="ico_file"><i className="far fa-file-alt"></i></span>
                                <small>{item.fileName}</small>
                            </a>
                            {props.IsDelete != null && props.IsDelete ?
                                <span className="trans btn_delete" onClick={(event) => handleDelete(event, item)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                                : null}
                        </span>
                    ))}
                </span>
                : null}
        </span>
    );
}
