import React, { Fragment, useEffect, useState } from 'react'
import useInputForm from '../../components/CustonHooks/FormHook'
import { SelectCustom } from '../../components/Helpers/SelectCustom'
import { useAcknowledgment } from '../../services/acknowledgment.service'

export default function FormAcknowledgement(props) {
  const [acknowledgement] = useState({ acknowledgmentTypeId: '', userProfileTo: '', text: '' })
  const [destinataries, setDestinataries] = useState([])

  const onSave = () => {
    inputs.acknowledgmentTypeId = parseInt(inputs.acknowledgmentTypeId);
    let userProfileTo = destinataries.find(
        destinatary => (
            //destinatary.nickname !== null && destinatary.nickname != '' ? destinatary.nickname : 
            destinatary.middleName + " " + destinatary.lastName + " " + destinatary.firstName
            ) === inputs.userProfileTo);

    if (userProfileTo) {
      inputs.userProfileTo = userProfileTo.userProfileId;
      useAcknowledgment.createAcknowledgmentUserProfile(inputs)
        .then(() => {
          props.updateRanking()
          cleanForm()
        })
    }
    else {
      alert("Colaborador invalido");
    }
  }

  useEffect(() => {
    useAcknowledgment.getUserProfileDestinataries()
      .then((data) => {
        setDestinataries(data)
      })
  }, [])


  const cleanForm = () => setInputs({ acknowledgmentTypeId: '', userProfileTo: '', text: '' })
  const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, acknowledgement)

  return (
    <Fragment>
      <form onSubmit={handleSubmit} id="recognition_form">
        <fieldset className='card shadows'>
          <h4  className="text fnt_medium">Reconoce al mejor</h4>
        
        <div className="form-group ">
          <label htmlFor="acknowledgmentTypeId" className=" col-form-label">Categoria</label>
          
            <SelectCustom
              method="AcknowledgmentType"
              controller="backend"
              textField="title"
              valueField="acknowledgmentTypeId"
              selectName="acknowledgmentTypeId"
              handleOnChange={handleInputChange}
              required={true}
              selectValue={inputs.acknowledgmentTypeId}
            />
        </div>
        <div className="form-group ">
          <label htmlFor="userProfileTo" className=" col-form-label">Colaborador</label>
            <input
              list="users"
              type="text"
              name="userProfileTo"
              className="form-control"
              onChange={handleInputChange}
              value={inputs.userProfileTo}
              placeholder='Seleccione una opción'
              required
            />
            <datalist id="users">
              {destinataries.map((to, index) =>
                <option key={index} value={
                  //(to.nickname === null || to.nickname === '') ? 
                  to.middleName + ' ' + to.lastName + ' ' + to.firstName 
                  //: to.nickname
                } />
              )}
            </datalist>
        </div>
        <div className="form-group ">
          <label htmlFor="text" className=" col-form-label">Mensaje</label>
            <textarea
              name="text"
              onChange={handleInputChange}
              value={inputs.text}
              className="form-control textarea"
              required
            />
        </div>
        <div className=" text-right">
          <button className="btn secundary minimum" onClick={cleanForm}>Limpiar</button>
          <button type='submit' className="btn primary minimum ml-2" form="recognition_form">Enviar</button>
        </div>
        </fieldset>

      </form>
    </Fragment>
  )
}