import React, { Component } from "react";
import Slider from "react-slick";
import { useQuestion } from "../../services/surveyQuestion.service";
import { AnswersSurvey } from "./answersSurvey";

export default class BoxSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      questionIndex: 1,
      questionCount: 0,
      questionIndexSave: -1,
    };
    this.lastSave = 0;
  }

  componentWillMount() {
    this.getQuestions(this.props.survey);
    this.setState({ questionCount: this.props.survey.questionCount });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.getQuestions(nextProps.survey);
      this.setState({
        questionCount: nextProps.survey.questionCount,
        questionIndex: 1,
        questionIndexSave: -1,
      });
    }
  }

  getQuestions = async (survey) => {
    useQuestion.getFrontQuestions(survey).then((result) => {
      if (result) {
        this.setState({ questions: result });
      }
    });
  };

  setQuestionIndexSave = (value) => {
    this.setState({ questionIndexSave: value });
  };

  setLastSave = (index) => {
    if (this.lastSave < index) {
      this.lastSave = index;
      this.slider.slickGoTo(this.lastSave + 1);
    }
  };

  handleQuestionChange = (questionSave) => {
    if (!questionSave) this.slider.slickPrev();
    this.setQuestionIndexSave(questionSave ? this.state.questionIndex - 1 : -1);
  };

  next = () => {
    this.slider.slickNext();
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ questionIndex: next + 1 }),
      adaptiveHeight: true,
      swipe: false,
    };

    const { questions, questionIndex, questionCount, questionIndexSave } =
      this.state;
    return (
      <div className="section_surveys">
        <div className="w-100">
          <h6 className="text fnt_medium mb-4">
            Pregunta <span>{questionIndex}</span>/<span>{questionCount}</span>
          </h6>
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {questions.map((question, index) => (
              <div className="outline" key={question.surveyQuestionId}>
                <h5 className=" question">{question.question}</h5>
                <div className="answers">
                  <AnswersSurvey
                    index={index}
                    question={question}
                    surveyUserProfileId={this.props.survey.surveyUserProfileId}
                    next={this.next}
                    setQuestionIndexSave={this.setQuestionIndexSave}
                    questionIndexSave={questionIndexSave}
                    setLastSave={this.setLastSave}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="box_arrows">
          <span
            className="arrow lf"
            onClick={() => this.handleQuestionChange(false)}
          >
            <i className="fas fa-angle-left mr-2"></i> Anterior
          </span>
          <span
            className="arrow rg"
            onClick={() => this.handleQuestionChange(true)}
          >
            {questionIndex != questionCount ? "Siguiente" : "Finalizar"}{" "}
            <i className="fas fa-angle-right ml-2"></i>
          </span>
        </div>
      </div>
    );
  }
}
