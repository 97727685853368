import React, { useState, useEffect } from "react";
import { categoryServiceService } from "../../services/categoryService.service";
import { ItemSocial } from "./itemSocial";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./interestGroup.scss";

export function InterestGroup() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Sitios de Interés</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">Grupos donde nuestra comunidad es protagonista</span>
          </div>
        </h4>
      </div>

      <div className="row">
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/HUB.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                HUB
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://hubtlc.com.mx/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/MAGAZINE.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                MAGAZINE
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://tlcmagazinemexico.com.mx/"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/TLCMagazineMexico/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/TLCMagazineMx"
                          />
                          <ItemSocial
                            socialIcoName="LinkedIn"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/tlcmagazinem%C3%A9xico/"
                          />
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/tlcmagazinemexico/"
                          />
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCdc9jntvdcgE49DNZ6ZzVVw"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/TLC%20INFINITI.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                TLC INFINITI
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.tlcasociados.com.mx/servicios/tlc-infiniti/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/TLC.Asociados.SC"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/tlcasociados"
                          />
                          <ItemSocial
                            socialIcoName="LinkedIn"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/tlc-asociados-s.c./"
                          />
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/tlc.asociados/"
                          />
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCl5zP6lVFyfbxnMeluQTZqQ"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
           <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/TLC.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                TLC
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.tlcasociados.com.mx"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/TRADE.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                TRADE
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.tradelawcollege.edu.mx/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/TradeLawCollege"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/TradeLawCollege"
                          />
                          <ItemSocial
                            socialIcoName="LinkedIn"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/trade-&-law-college/"
                          />
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UC8hsqT4iZqVmILU16Y8f3wA"
                            />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/WCA.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                WAC
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://worldcomplianceassociation.com/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/Aduanas.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                ANAM
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://anam.gob.mx/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/OMC.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                OMC
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.wto.org/indexsp.htm"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/SAT.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                SAT
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.sat.gob.mx/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/SE.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                SE
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.gob.mx/se/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/InterestGroups/UCO.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                WCO
              </h6> 
              <br></br>
              <br></br>
              <div className="box_data">
                  {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.wcoomd.org/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
       </div>
    </section>
  );
}
