import { callApi, callApiFile } from "./service";

export const ecommerceService = {
  getProviders,
  getProviderProducts,
  getPaymentMethods,
  payOrder,
  getPurchaseOrder,
  getPurchaseOrderDetail,
  downloadSaleAsPDF,
  payMembership,
  createMembership,
  cancelMembership,
  membershipStatus,
};

async function getProviders(categoryId = "") {
  return await callApi(`/Ecommerce/Providers?categoryId=${categoryId}`, "GET")
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return [];
    })
    .catch((error) => {
      console.log("error", error);
      return [];
    });
}

async function getProviderProducts(providerId) {
  return await callApi(
    "/Ecommerce/ProviderProducts?providerId=" + providerId,
    "GET"
  )
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return { CompanyProducts: [] };
    })
    .catch((error) => {
      console.log("error", error);
      return { CompanyProducts: [] };
    });
}

async function getPaymentMethods() {
  return await callApi("/Ecommerce/PaymentMethods", "GET")
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return [];
    })
    .catch((error) => {
      console.log("error", error);
      return [];
    });
}

async function payOrder(model) {
  return await callApi("/Ecommerce/PayOrder", "POST", model)
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return undefined;
    })
    .catch((error) => {
      console.log("error", error);
      return undefined;
    });
}

async function getPurchaseOrder() {
  return await callApi("/Ecommerce/PurchaseOrders", "GET")
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return [];
    })
    .catch((error) => {
      console.log("error", error);
      return [];
    });
}

async function getPurchaseOrderDetail(id) {
  return await callApi("/Ecommerce/PurchaseOrderDetail?id=" + id, "GET")
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return {};
    })
    .catch((error) => {
      console.log("error", error);
      return {};
    });
}

async function downloadSaleAsPDF(id) {
  return await callApiFile("/Ecommerce/DownloadSaleAsPDF?orderId=" + id, "GET")
    .then(function (blob) {
      if (blob.size !== 0) {
        var FileSaver = require("file-saver");
        FileSaver.saveAs(blob, "order_" + id + ".pdf");
      }
    })
    .catch((error) => {
      console.log("error", error);
      return {};
    });
}

async function payMembership(request) {
  request.applicationId = process.env.REACT_APP_APPLICATION_ID;
  return await callApi("/ecommerce/createmembership", "POST", request, {
    SkipValidationToken: true,
  })
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return undefined;
    })
    .catch((error) => {
      console.log("error", error);
      return undefined;
    });
}

async function createMembership(request) {
  request.applicationId = process.env.REACT_APP_APPLICATION_ID;
  return await callApi("/ecommerce/createmembership", "POST", request, {
    SkipValidationToken: true,
  })
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return undefined;
    })
    .catch((error) => {
      console.log("error", error);
      return undefined;
    });
}

async function cancelMembership(subscriptionId) {
  return await callApi(
    `/ecommerce/cancelmembership/${subscriptionId}`,
    "Delete"
  )
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return undefined;
    });
}
async function membershipStatus() {
  return await callApi(`/ecommerce/membershipstatus`, "Get")
    .then((result) => {
      if (result.responseCode === "00") return result.data;
      else return false;
    })
    .catch((error) => {
      console.log("error", error);
      return undefined;
    });
  }