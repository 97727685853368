import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoCompany from "../../assets/imgs/R_C.png";
import { userService } from "../../services/user.service";
import { useForm } from "react-hook-form";

export function ResetPassword(props) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    userService.resetPassword(data);
  };

  const [showPass, setShowPass] = useState("password");
  const [showIco, setShowIco] = useState("fa-eye-slash");

  const setInputPass = () => {
    setShowPass(showPass === "password" ? "text" : "password");
    setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
  };

  return (
    <div className="wrapper_reset_password">
      <div className="content_login">
        <div className="box_msj">
          <h1 className="text fnt_black ttls_black">¡Bienvenidos!</h1>
          <p className="paragraph parag_big mb-0">
            Todo el poder de la comunidad en un solo espacio.
          </p>
          <div className="box_logo_company_login">
            <img src={LogoCompany} alt="Name Company" />
          </div>
        </div>
        <div className="box_form_pass">
          <h4 className="ttl_form text fnt_bold ">
            Ingresa tu nueva contraseña
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              id="code"
              name="Code"
              value={props.location.search.split("code=")[1]}
              {...register("Code")}
            />
            <input
              type="hidden"
              id="email"
              name="Email"
              value={props.location.search.split("email=")[1].split("&")[0]}
              {...register("Email")}
            />

            <div className="form-group">
              <span className="box_input_icon">
                <input
                  type={showPass}
                  className="form-control"
                  id="password"
                  name="Password"
                  placeholder="Nueva contraseña"
                  {...register("Password")}
                />
                <span className="ico_show_pass" onClick={setInputPass}>
                  <i className={`fas ${showIco}`}></i>
                </span>
              </span>
            </div>
            <div className="form-group">
              <span className="box_input_icon">
                <input
                  type={showPass}
                  className="form-control"
                  id="new_password"
                  name="ConfirmPassword"
                  placeholder="Confirmar nueva contraseña"
                  {...register("ConfirmPassword")}
                />
                <span className="ico_show_pass" onClick={setInputPass}>
                  <i className={`fas ${showIco}`}></i>
                </span>
              </span>
            </div>
            <div className="mt-4 ">
              <Link to="/login" className="btn secundary ml-1 mr-1">
                Cancelar
              </Link>
              <button type="submit" className="btn primary ml-1 mr-1">
                Guardar contraseña
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
