import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { companyService } from '../../services/control/company.service';

export function SearchCompany(props) {
    const [companiesData, setCompaniesData] = useState({ companies: [] });
    const handleDelete = (data) => {
        companyService.deleteCompany(data).then((result) => { getCompanies(); });
    };

    useEffect(() => {
        getCompanies(props.statusValue);
    }, [props.statusValue]);

    async function getCompanies(stat) {
        companyService.get(stat).then((result) => {
            if (result) {
                setCompaniesData({ companies: result });
            }
        });
    }
//comment force publish


    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">

                <thead>
                    <tr>
                        <th className="text fnt_medium">ID
					</th>
                        <th className="text fnt_medium min_width">Nombre
					</th>
                        <th className="text fnt_medium">Dominio
					</th>
                        <th className="text fnt_medium">Activo
					</th>
                    </tr>
                </thead>
                <tbody>
                    {companiesData.companies.map(company=> (
                        <tr key={company.companyId}>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.companyId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.companyName}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-company/" + company.companyId}>{company.primeDomain}</Link>
                            </td>
                            <td>{company.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                {
                                    company.active ? 
                                    <span className="icon_tables" onClick={() => handleDelete(company.companyId)}>
                                    <i className="far fa-trash-alt"></i>
                                    </span>
                                    : ""
                                }
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}