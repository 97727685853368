import React, { useEffect, useState } from "react";
import { useSurvey } from "../../services/survey.service";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { ResponsiveBar} from '@nivo/bar'

export function SurveyChart(props) {
  
  const [chartData , setChartData ] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if ((props.surveyId != "" && props.surveyId) || (props.surveyQuestionId != "" && props.surveyQuestionId) ||  (props.chartGrouping != "" && props.chartGrouping)) 
      getChartData(props.surveyId, props.surveyQuestionId, props.chartGrouping);
      
  }, [props]);


  const getChartData = async (surveyId, questionId, chartGroupId) => {
    
    if(props.anwerTypeId != 1){
    useSurvey.getChartData(surveyId, questionId, chartGroupId).then((result) => {
      if (result) {
          if(chartGroupId == 1){         
              let dataValues = result.filter(element => element.id == 1)[0].data;
              setChartData(JSON.parse(dataValues))   
          }
          else {
            
            let dataValues = result.filter(element => element.id == 0)[0].data;
            setChartData(JSON.parse(dataValues))

            let keyValues = result.filter(element => element.id == 1)[0].data;
            setKeys(JSON.parse(keyValues))

          }
      }

    });
    }
  };

  const styles = {
    root: {
      fontFamily: "consolas, sans-serif",
      textAlign: "center",
      position: "relative",
      width: 900,
      height: 700,
    },
    totalLabel: {
      fontSize: 24,
    },
  };



  return (
    <div className="p-3">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt mb-0">
          <div>
          
            <h2 className="mb-0 text fnt_medium">{props.questionTitle}</h2>
            <div className="drawer__cont_svg" style={styles.root}>
               {props.chartGrouping == 1 ? 
                <ResponsivePie
                data={chartData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              /> 
                : "" 
              } 
               {props.chartGrouping != 1 ? 
                    <ResponsiveBar
                        data={chartData}
                        keys={keys}
                        indexBy="Grupo"
                        margin={{ top: 60, right: 100, bottom: 100, left: 60 }}
                        padding={0.45}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: -45,
                          legend: 'Grupo',
                          legendPosition: 'middle',
                          legendOffset: 50
                      }}
                      axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'total',
                          legendPosition: 'middle',
                          legendOffset: -40
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                      legends={[
                          {
                              dataFrom: 'keys',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 120,
                              translateY: 0,
                              itemsSpacing: 2,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemDirection: 'left-to-right',
                              itemOpacity: 0.85,
                              symbolSize: 20,
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemOpacity: 1
                                      }
                                  }
                              ]
                          }
                      ]}
                      role="application"
                      ariaLabel="Nivo bar chart demo"
                      // barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                    />

                    : "" 
              } 
 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
