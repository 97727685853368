import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const rewardCouponService = {
    create,
    getCatalogs
};

async function create(data) {
    const formData = new FormData();
    formData.append("rewardProductId", data.rewardProductId);
    formData.append("companyId", data.companyId);
    formData.append("startDate", data.startDate);
    formData.append("endDate", data.endDate);
    formData.append("couponData", data.couponData);
    return callApiFileStorage('/BackOffice/RewardCoupon', 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/controlreward/1")
            } else {
                console.log(result.responseMessage);
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}


async function getCatalogs() {
    return await callApi('/BackOffice/RewardCoupon/Catalogs', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}


