import React, { createRef, useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorCommentsBox } from "./editorCommentsBox";
import { ItemsComment } from './itemsComment';
import { companyPostCommentService } from "../../services/companyPostComment.service";
import swal from "sweetalert";

export function CommentsBox(props) {
  const [comments, setComments] = useState([]);
  const [btnBack, setBtnBack] = useState(true);

  async function getComment(id) {
    companyPostCommentService.getById(id)
      .then((result) => {
        if (result && result != null && result.length > 0) {
          props.updateCountComment(result.length);
          setComments(result);
        }
      });
  }

  async function newComment(comment) {
    let newComm = {
      History: comment,
      CompanyPostId: props.companyPostId
    };

    companyPostCommentService.create(newComm)
      .then((result) => {
        getComment(props.companyPostId);
      });
  }

  async function newSubComment(comment, SubcommentId) {
    let newSubComm = {
      Comment: comment
    };

    companyPostCommentService.newSubComment(newSubComm, SubcommentId)
      .then((result) => {
        getComment(props.companyPostId);
      });
  }

  async function ReportComment(CommentId, SubCommentId) {
    companyPostCommentService.reportComment(props.postType, CommentId, (SubCommentId == undefined ? -1 : SubCommentId),)
      .then((result) => {
        swal({ text: "Comentario Reportado", icon: "info" });
        setComments([]);
        getComment(props.companyPostId);
      });
  }

  async function GetReportComment(reportId) {
    companyPostCommentService.getReportById(props.reportId)
      .then((result) => {
        let arrayresult = [];
        if (result != null) {
          arrayresult.push(result);
        }
        setComments(arrayresult);
      });
  }

  async function DeleteComment(CommentId, SubCommentId) {
    companyPostCommentService.deleteComment(CommentId, (SubCommentId == undefined ? -1 : SubCommentId),)
      .then((result) => {
        if (result != null) {
          swal({ text: result, icon: "info" });
          GetReportComment(props.reportId);
        }
      });
  }

  useEffect(() => {
    setBtnBack(false);
    props.setBandera(1);

    if (props.isShow && props.companyPostId && props.reportId === undefined) {
      setBtnBack(true);
      getComment(props.companyPostId);
    }
    if (props.bandera == 1) {
      if (props.isShow && props.reportId) {
        setBtnBack(false);
        GetReportComment(props.reportId);
      }
    }
  }, []);

  return (
    <div className={`box_comments_box ${props.isShow}`}>
      <div className="head_list_comment">
        {btnBack ? (
          <span className="btngoback" onClick={props.handleCommentBox}>
            <i className="fas fa-angle-left"></i>
          </span>) : ""}
        <h6>
          Todos los comentarios <strong>{comments ? comments.length : 0}</strong>
        </h6>
      </div>
      <div className="contain_comments">
        {comments ? comments.map((item) => (
          <ItemsComment contenido={item} profileUser={props.profileUser} clickSave={newSubComment} report={ReportComment} btnBack={btnBack} eliminar={DeleteComment} />
        )) : ""}

      </div>
      {btnBack ? (<EditorCommentsBox profileUser={props.profileUser} clickSave={newComment} />) : ""}
    </div>
  );
}
