import React, { useState, useEffect } from "react";
import { useQuestion } from '../../services/surveyQuestion.service'

export function SurveyAnswerList(props) {
    const [answerList, setAnswerList] = useState(0);

    useEffect(() => {
        getAnswerList(props.questionId);    
	}, [props.questionId]);

    const getAnswerList = async (questionId) => {
        useQuestion.getQuestionAnswerList(questionId).then((result) => {
          if (result) {
            setAnswerList(result);
          }
        });
      };

    return (
		<div className="table-responsive card shadows p-3">
			<table className="wrapper_table table table-hover table-sm">
				<thead>
					<tr>
						<th className="text fnt_medium min_width">Usuario </th>
						<th className="text fnt_medium">Respuesta</th>
					</tr>
				</thead>
				<tbody>
					{answerList && answerList.map(answer => (
						<tr key={answer.surveyUserProfileAnswerId}>
							<td className="text-left">
                                {answer.userName}
							</td>
							<td className="text-left">{answer.answerText}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
    );
}