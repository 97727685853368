import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import Spinner from "react-spinkit";
import "./blockScreen.scss";

export const BlockScreen = ({ color }) => {
  const blocking = useSelector((state) => state.blocking);

  return (
    <Modal isOpen={blocking} className="wrapper_block">
      <div className="box_content_block">
        <Spinner name="ball-pulse-rise" color={color ? color : "#ffffff"} />
      </div>
    </Modal>
  );
};
