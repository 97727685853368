import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { JobBankService } from "../../services/jobBank.service";

export function FormSection({toggleView, job}) {
    const [sections, setSections] = useState([]);
    const [sectionValues, setSectionValues] = useState([]);

    useEffect(() => {
        JobBankService.getJobSections().then(data => {
            setSections(data);
            setSectionValues(data.map((section) => {
                return {
                    JobSectionTypeId: section.jobSectionTypeId,
                    JobSection: {
                        Description: "",
                        JobSectionTypeId: section.jobSectionTypeId,
                    },
                }
            }));
        });
    }, [])
    
    const onEditorStateChange = (editorState, section) => {
        let descriptionHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let newSectionValues = sectionValues.map(x => {
            if (x.JobSectionTypeId === section.jobSectionTypeId) {
                x.JobSection.Description = descriptionHtml;
                x.JobSection.jobSectionTypeId = section.jobSectionTypeId;
            }
            return x;
        })
        setSectionValues(newSectionValues);
    };

    const onSave = (e) => {
        var validSections = false;
        sectionValues.map(x => {
            if(x.JobSection.Description != "" && x.JobSection.description != '<p></p>' && x.JobSection.description != '<p></p>\n')
                validSections = true;
        })
        console.log(validSections)
        if(validSections == false){
            Swal.fire({
                text: "Favor de completar al menos una sección",
                icon: "warning",
                showCloseButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            return;
        }
        let JobSections = {
            JobId: job?.jobId,
            JobSection: sectionValues.flatMap(x => x.JobSection),
        }
        JobBankService.insertJobSections(JobSections).then(
            data => {
                if(data !== null)
                    Swal.fire({
                        text: "Se ha registrado la oferta de trabajo",
                        icon: "success",
                        showCloseButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })
                    .then(() => {
                        window.location.href = "/job-bank";
                    });
            }
        );
    }

    const returnJob = () => {
        if(job?.jobId){
            window.location.href = "/edit-job/" + job.jobId;
        } else {
            toggleView()
        }
    }

    return (
        <>
        <div className="bar_step_reg">
            <div className="box_item_step step_active">
                <span className="item_step">1</span>
            </div>
            <div className="box_item_step step_active">
                <span className="item_step">2</span>
            </div>
        </div>
          
        <div className="content_shadows_form">
            <div className="text-right mb-2">
            <span className="goback trans " onClick={() => returnJob()}>
                <ion-icon name="chevron-back"></ion-icon>
                <span>Volver</span>
            </span>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h6 className="text fnt_medium mb-3">Información adicional</h6>
                </div>
                {sections.map((section, index) => {
                    return (
                    <div className="col-md-12" key={index}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span
                                className="input-group-text"
                                >
                                    {section.name}
                                </span>
                            </div>
                            <div>
                                <Editor
                                    onEditorStateChange={(e) => onEditorStateChange(e, section)}
                                    wrapperClassName="wrapper__editor_text"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    toolbar={{
                                    options: [
                                        "inline",
                                        "colorPicker",
                                        "blockType",
                                        "fontSize",
                                        // "fontFamily",
                                        "list",
                                        "textAlign",
                                        // "image",
                                        "link",
                                        "remove",
                                        "history",
                                    ],
                                    inline: {
                                        options: [
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strikethrough",
                                        ],
                                    },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    )
                })
                }
            </div>
            <div className="row">
                <div className="col-12 mt-4 text-center">
                    <button className="btn primary" onClick={onSave}>
                    Registrar empleo
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}