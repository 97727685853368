import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import { JobBankService } from "../../services/jobBank.service";
import Select from "react-select";
import "./jobBank.scss";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from "react-select/creatable";
import { ValidacionEmail } from "../../utils/ValidacionMail";
import { useHistory } from "react-router-dom";

import swal from "sweetalert";
import Swal from "sweetalert2";

export function FormEditJob({id, job, setJob, toggleView}) {
  const history = useHistory();
  const [datos, setDatos] = useState({});

  const [editorState, setEditorState] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [defaultContentState, setDefaultContentState] = useState();

  useEffect(() => {
    JobBankService.getCategories().then((result) => {
      if (!result) return;
      setOptions(
        result.map((x) => ({ value: x.jobCategoryId, label: x.name }))
      );
    });

    JobBankService.getJobById(id).then((result) => {

      // se obtiene un objeto del tipo EditorState para la descripcion del trabajo
      let blocksFromHtml = htmlToDraft(result.description);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      let editorState = EditorState.createWithContent(contentState)
      setDefaultContentState(editorState);
      setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      
      getFile(result.companyImage);

      setJob(result);
      
      setDatos({
        title: result.title,
        categoria: result.jobCategory,
        location: result.location,
        direction: result.direction,
        titleCompany: result.companyName,
        imagen: result.companyImage,
        infoSalarial: result.salary,
        descripcion: result.description,
        phoneNumber: result.phoneNumber,
        mail: result.email,
      });

      setSelectedOption(result.jobCategory.map((element) => {
          return { value: element.jobCategoryId, label: element.name};
        }
      ));
    });
  }, []);

  const getFile = async (url) => {
    let barra = url.lastIndexOf("/");
    let punto = url.lastIndexOf(".");
    let nombre = url.substring(barra + 1, punto);

    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], nombre);
    let container = new DataTransfer(); 
    container.items.add(file);
    document.querySelector('#file-upload').files = container.files;
  }

  function updateImage(e) {
    var file = e.target.files[0];
    inputs.imagen = file;
  }

  const onSave = () => {
    inputs.descripcion = editorState;
    inputs.categoria = selectedOption;
    let error = ValidacionEmail(inputs.mail);
    if (error === false) {
      swal({ text: "Correo incorrecto, por favor revise.", icon: "error" });
      return;
    }
    if (inputs.categoria.length === 0) {
      swal({ text: "Seleccion al menos una categoría", icon: "error" });
      return;
    }
    if (inputs.descripcion === "") {
      swal({ text: "Ingrese una descripción", icon: "error" });
      return;
    }
    insertJob();
  };

  async function insertJob() {
    let formData = new FormData();
    formData.append("JobId", job.jobId);

    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("Title", inputs.title);
    //Intentar agarrar lo que pone el editor y ponerlo un string
    formData.append("Description", inputs.descripcion);
    formData.append("Currency", "MXN");
    formData.append("Salary", inputs.infoSalarial);
    formData.append("Location", inputs.location);
    formData.append("Direction", inputs.direction);
    formData.append("CompanyName", inputs.titleCompany);
    //En CompanyImage debería ir el logo de la empresa
    formData.append("CompanyImage", inputs.imagen);
    formData.append("PhoneNumber", inputs.phoneNumber);
    formData.append("Email", inputs.mail);

    selectedOption.forEach((element, index) => {
      formData.append(
        `JobCategory[${index}].JobCategoryId`,
        element?.__isNew__ ? 0 : element.value
      );
      formData.append(`JobCategory[${index}].Name`, element.label);
    });

    JobBankService.updateJob(formData).then((result) => {
      JobBankService.getJobById(id).then((result) => {
        // se obtiene un objeto del tipo EditorState para la descripcion del trabajo
        let blocksFromHtml = htmlToDraft(result.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        let editorState = EditorState.createWithContent(contentState)
        setDefaultContentState(editorState);
        setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        
        getFile(result.companyImage);
  
        setJob(result);
        
        setDatos({
          title: result.title,
          categoria: result.jobCategory,
          location: result.location,
          direction: result.direction,
          titleCompany: result.companyName,
          imagen: result.companyImage,
          infoSalarial: result.salary,
          descripcion: result.description,
          phoneNumber: result.phoneNumber,
          mail: result.email,
        });
  
        setSelectedOption(result.jobCategory.map((element) => {
            return { value: element.jobCategoryId, label: element.name};
          }
        ));
      });
      Swal.fire({text: "Se ha actualizado la oferta de trabajo", icon: "success", showCloseButton: true, allowOutsideClick: false, allowEscapeKey: false})
        .then(() => {
            toggleView();
        });
    })
    
  }

  const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
    onSave,
    datos
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div className="wrapper_reg_services">
    <div className="bar_step_reg">
      <div className="box_item_step step_active">
        <span className="item_step">1</span>
      </div>
      <div className="box_item_step ">
        <span className="item_step">2</span>
      </div>
    </div>
      <div className="content_services">
        <div className="wrapper_form">
          <form onSubmit={handleSubmit}>
            <div className="content_shadows_form">
              <div className="row mt-3">
                <div className="col-md-12">
                  <h6 className="text fnt_medium mb-3">
                    Información de la vacante
                  </h6>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Título de la vacante</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Título de la vacante"
                      name="title"
                      required={true}
                      defaultValue={job.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="categoria">
                      Categoría
                    </label>
                    <CreatableSelect
                      closeMenuOnSelect={false}
                      value={selectedOption}
                      required={true}
                      onChange={setSelectedOption}
                      placeholder="Selecciona una categoría"
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={options}
                      id="categoria"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="direction">Dirección</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Ubicación"
                      name="direction"
                      errormessage=""
                      onChange={handleInputChange}
                      required={true}
                      defaultValue={job.direction}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="location">Localidad</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Localidad"
                      name="location"
                      errormessage=""
                      onChange={handleInputChange}
                      required={true}
                      defaultValue={job.location}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="titleCompany">Nombre de la empresa</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Nombre de la empresa"
                      name="titleCompany"
                      onChange={handleInputChange}
                      required={true}
                      defaultValue={job.companyName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="mail">Correo</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Correo electrónico"
                      name="mail"
                      onChange={handleInputChange}
                      required={true}
                      defaultValue={job.email}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Número de contacto</label>
                    <input
                      className="form-control form-control-sm"
                      placeholder="Número de teléfono"
                      name="phoneNumber"
                      maxLength={10}
                      minLength={10}
                      onKeyPress={(e) => {
                        // Solo se admiten números
                        e = e || window.event;
                        var charCode = e.keyCode || e.which;
                        var charStr = String.fromCharCode(charCode);
                        if(isNaN(charStr))
                        e.preventDefault();
                      }}
                      onChange={handleInputChange}
                      required={true}
                      defaultValue={job.phoneNumber}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group isHidde">
                    <label htmlFor="">Logo empresa</label>
                    <input
                      type="file"
                      className=""
                      id="file-upload"
                      placeholder={job.companyImage}
                      accept="image/*"
                      name="imagen"
                      onChange={(x) => updateImage(x)}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div className="row mt-3">
                <div className="col-md-12">
                  <h6 className="text fnt_medium mb-3">Información salarial</h6>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Salario bruto</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text amount">
                          <input
                            type="radio"
                            name="exampleRadios"
                            aria-label="Radio button for following text input"
                          />
                        </div>
                        <span className="input-group-text amount">$</span>
                      </div>
                      <input
                        type="number"
                        min={0}
                        step="0.01"
                        // disabled={true}
                        className="form-control"
                        name="infoSalarial"
                        onChange={handleInputChange}
                        required={true}
                        defaultValue={job.salary}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3">
                <div className="col-md-12">
                  <h6 className="text fnt_medium mb-3">
                    Descripción de la vacante
                  </h6>
                </div>
                <div className="col-md-12">
                  {defaultContentState && 
                    <Editor
                      defaultEditorState={defaultContentState}
                      onEditorStateChange={onEditorStateChange}
                      wrapperClassName="wrapper__editor_text"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={{
                        options: [
                          "inline",
                          "colorPicker",
                          "blockType",
                          "fontSize",
                          // "fontFamily",
                          "list",
                          "textAlign",
                          // "image",
                          "link",
                          "remove",
                          "history",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                    />
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4 text-center">
                  <button className="btn primary" type="submit">
                    Modificar empleo
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
