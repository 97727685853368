import { toggleBlocking } from "../store/actions";
import store from "../store/configureStore";

let stack = [];

export const addToStack = (urlRequest) => {
  stack.push(urlRequest);
  setBlocking();
};
export const removeFromStack = (urlRequest) => {
  if (stack.length > 0) {
    stack = stack.filter((item) => item != urlRequest);
    setBlocking();
  }
};

const setBlocking = () => {
  store.dispatch(toggleBlocking(stack.length > 0));
};
