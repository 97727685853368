import React from "react";

export function Searches({ placeHolder, handleSearch }) {
    return (
        <div className="wrapper_searches">
            <input
                type="text"
                className="form-control"
                placeholder={placeHolder}
                onChange={(e) => handleSearch(e.target.value)}
            />
            <span className="ico_form">
                <ion-icon name="search-outline"></ion-icon>
            </span>
        </div>
    );
}
