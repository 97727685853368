import React, { Component } from "react";
import { ToolDiscount } from "../../../components/toolDiscount/toolDiscount";

import ProviderImage from "../../../components/providerImage/providerImage";
import ProviderVideo from "../../../components/providerVideo/providerVideo";
import ItemProduct from "../../../components/itemProduct/itemProduct";

import "./provider.scss";
import { ecommerceService } from "../../../services/ecommerce.service";

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerProducts: {
        provider: {},
        products: [],
      },
    };
    this.isCaringCommunity =
      props.location.pathname.includes("caring-community");
  }

  componentWillMount() {
    ecommerceService
      .getProviderProducts(this.props.match.params.id)
      .then((result) => {
        this.setState({ providerProducts: result });
      });
  }

  render() {
    const { providerProducts } = this.state;
    const { provider } = providerProducts;
    const { products } = providerProducts;
    return (
      <div className="wrapper_provider padd ">
        <div className="mb-4 divtop">
          <div className="box_ttl_opt">
            <div className="box__providers">
              <div className="box_logo_shop ">
                <img src={provider.urlLogoClrs} alt="LogoProvider" />
              </div>
            </div>
            <ToolDiscount isCaringCommunity={this.isCaringCommunity} />
          </div>
        </div>
        <h5 className="font-italic mb-0">
          {provider.info
            ? provider.info
            : "Compra aquí tu tarjeta de regalo con los mejores descuentos"}
        </h5>

        <div className="row">
          <div className="col">
            <div className="info_media">
              {provider.urlVideo != "" ? (
                <ProviderVideo urlVideo={provider.urlVideo} />
              ) : (
                <ProviderImage urlImageVideo={provider.urlImageVideo} />
              )}
            </div>
          </div>
        </div>
        <div className="wrapper_items_provider">
          <div className="row">
            {products.map((item) => (
              <ItemProduct product={item} provider={provider} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
