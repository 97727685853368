import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { ItemJobPublished } from "./itemJobPublished";
import { TtlsJobBank } from "./ttlsJobBank";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import Swal from "sweetalert2";

export function JobsPublished() {
  const [activeTab, setActiveTab] = useState("1");
  const [activeJobs, setActiveJobs] = useState([]);
  const [inactiveJobs, setInactiveJobs] = useState([]);
  const [activePages, setActivePages] = useState([]);
  const [activeTotalCount, setActiveTotalCount] = useState(0);
  const [activeTotalPages, setActiveTotalPages] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inactivePages, setInactivePages] = useState([]);
  const [inactiveTotalCount, setInactiveTotalCount] = useState(0);
  const [inactiveTotalPages, setInactiveTotalPages] = useState(0);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);

  useEffect(() => {
    getActiveJobsByUserId(1);
    getInactiveJobsByUserId(1);
  }, []);

  const getActiveJobsByUserId = (pageIndex) => {
    JobBankService.getJobsByUserId(
      localStorage.getItem("userId"),
      true,
      pageIndex,
      8
    ).then((result) => {
      setActiveJobs(result.list || []);
      setActiveTotalCount(result.totalCount);
      setActiveTotalPages(result.totalPages);
      setActiveCurrentPage(pageIndex);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item " + (pageIndex === index ? " active" : "")}
            key={index}
          >
            <a
              className="page-link"
              onClick={() => getActiveJobsByUserId(index)}
            >
              {index}
            </a>
          </li>
        );
      }
      setActivePages(rows);
    });
  };

  const getInactiveJobsByUserId = (pageIndex) => {
    JobBankService.getJobsByUserId(
      localStorage.getItem("userId"),
      false,
      pageIndex,
      8
    ).then((result) => {
      setInactiveJobs(result.list || []);
      setInactiveTotalCount(result.totalCount);
      setInactiveTotalPages(result.totalPages);
      setInactiveCurrentPage(pageIndex);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item " + (pageIndex === index ? " active" : "")}
            key={index}
          >
            <a
              className="page-link"
              onClick={() => getInactiveJobsByUserId(index)}
            >
              {index}
            </a>
          </li>
        );
      }
      setInactivePages(rows);
    });
  };

  const returnActivePreviousPage = () => {
    if (activeCurrentPage > 1) {
      getActiveJobsByUserId(activeCurrentPage - 1);
    }
  };

  const advanceActiveNextPage = () => {
    if (activeCurrentPage < activeTotalPages) {
      getActiveJobsByUserId(activeCurrentPage + 1);
    }
  };

  const returnInactivePreviousPage = () => {
    if (inactiveCurrentPage > 1) {
      getInactiveJobsByUserId(inactiveCurrentPage - 1);
    }
  };

  const advanceInactiveNextPage = () => {
    if (inactiveCurrentPage < inactiveTotalPages) {
      getInactiveJobsByUserId(inactiveCurrentPage + 1);
    }
  };

  const deleteJob = (jobId) => {
    JobBankService.deleteJob(jobId).then(() => {
      getActiveJobsByUserId(1);
      getInactiveJobsByUserId(1);
      Swal.fire({
        text: "Trabajo eliminado correctamente",
        icon: "success",
        allowOutsideClick: false,
      })
    });
  };

  const activateJob = (jobId) => {
    JobBankService.activateJob(jobId).then(() => {
      getActiveJobsByUserId(1);
      getInactiveJobsByUserId(1);
    });
  };

  return (
    <section className="wrapper_jobbank padd">
      <TtlsJobBank tituloInJobs="Bolsa de trabajo" />
      <section className="contain_gral_jobbank">
        <div className="sec_catalog_listjobs">
          <h5 className="text fnt_medium mb-4">Mis empleos publicados</h5>
          <Link to="/job-bank" className="goback trans">
            <span className="ml-1">Ir a Bolsa de trabajo</span>
          </Link>
          <br></br>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => setActiveTab("1")}
              >
                Activas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => setActiveTab("2")}
              >
                Desactivadas
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="cont_box_items_jobs">
                <div className="options_order">
                  <div>
                    <strong>{activeTotalCount}</strong> empleos
                  </div>
                  {/* <div className="d-flex align-items-center">
                    <span className="w-100">Ordenar por </span>
                    <select className="form-control form-control-sm">
                      <option value="Fecha">Fecha</option>
                      <option value="Salario">Salario</option>
                    </select>
                  </div> */}
                </div>
                <div className="box_items_jobs row">
                  {activeJobs
                    .filter((x) => x.active)
                    .map((job) => (
                      <ItemJobPublished key={job.jobId} job={job} misEmpl={true} deleteJob={deleteJob}/>
                    ))}
                </div>
                <div className="mt-3">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          onClick={returnActivePreviousPage}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      {activePages}
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Next"
                          onClick={advanceActiveNextPage}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="cont_box_items_jobs">
                <div className="options_order">
                  <div>
                    <strong>{inactiveTotalCount}</strong> empleos
                  </div>
                  {/* <div className="d-flex align-items-center">
                    <span className="w-100">Ordenar por </span>
                    <select className="form-control form-control-sm">
                      <option value="Fecha">Fecha</option>
                      <option value="Salario">Salario</option>
                    </select>
                  </div> */}
                </div>
                <div className="box_items_jobs row">
                  {inactiveJobs
                    .filter((x) => !x.active)
                    .map((job) => (
                      <ItemJobPublished key={job.jobId} job={job} misEmpl={true} activateJob={activateJob}/>
                    ))}
                </div>
                <div className="mt-3">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          onClick={returnInactivePreviousPage}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      {inactivePages}
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Next"
                          onClick={advanceInactiveNextPage}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
        <AsideCategoriesJobs />
      </section>
    </section>
  );
}
