import { callApi } from './service'

export const helperService = {
    getGenericCatalog
};

async function getGenericCatalog(method) {
    return callApi(method, 'GET').then((result) => {
        if (result.responseCode)
            if (result.responseCode === '00')
                return result.data;
            else {
                alert(result.responseMessage);
                return null;
            }
        else {
            return result;
        }
    }, () => {
        if (method === '/backoffice/colaborator') {
            return [{ text: 'Jorge Rial', value: 1 }]
        }
    });
}

