import { callApi } from './service'
import swal from "sweetalert";

export const useSurveyUserProfileAnswer = {
    createSurveyUserProfileAnswer,
    updateSurveyUserProfileAnswer,
    deleteSurveyUserProfileAnswer,
    getSurveysUserProfileAnswer,
    getSurveysUserProfileAnswersByQuestionId
};
async function createSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" });
            return null;
        }
    });
}
async function getSurveysUserProfileAnswer() {
    return callApi('/backend/SurveyUserProfileAnswer', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getSurveysUserProfileAnswersByQuestionId(idQuestion, surveyUserProfileId) {
    return callApi('/backend/SurveyUserProfileAnswer/' + idQuestion.toString() + '/' + surveyUserProfileId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" });
            return null;
        }
    });
}
async function deleteSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}