import { useEffect, useState } from 'react';

const useInputForm = (callback,initialValues) => {
    const [inputs, setInputs] = useState(initialValues);

    const handleSubmit = (event) => {
        if (event)
            event.preventDefault();
        callback(event);
    }
    useEffect(() => {
        setInputs(initialValues);
    }, [initialValues]);
    const handleInputChange = (event) => {
        event.persist();       
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }));
    }
    const handleInputFileChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.files.length > 0? event.target.files[0]: null }));
    }

    return {
        handleSubmit,
        handleInputChange,
        handleInputFileChange,
        inputs,
        setInputs
    };
}
export default useInputForm;

