import React, { Fragment } from 'react';

export default function AcknowledgementRanking(props) {
  return (
    <div className="box_ranking">
      <h5 className="text fnt_medium">Ranking</h5>
      <div className="card shadows">
        {props.top10.length > 0 &&
          <div className="item_ranking">
            <h5 className="text fnt_medium">{props.isActive.title}</h5>
            <div className="content_item_ranking">
              <div className="box_avatar_user">
                <span className="avatar">
                  <img src={props.top10[0].urlProfileImage} alt="NameUser" />
                </span>
              </div>
              <div className="ml-2">
                <h6 className="mb-0">{props.top10[0].middleName} {props.top10[0].lastName} {props.top10[0].firstName}</h6>
                <p className="mb-0"><small>{props.top10[0].count} Reconocimientos</small></p>
              </div>
            </div>

            <div className="text-right">
              <span className="btn_links" onClick={() => props.setShowAcknowledgementModal(true)}>Ver todos</span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}