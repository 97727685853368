import React, { useEffect, useState } from "react";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { TtlsJobBank } from "./ttlsJobBank";
import { Link } from "react-router-dom";
import { ApplicantForm } from "./applicantForm";
import { JobBankService } from "../../services/jobBank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import Swal from "sweetalert2";
import "./jobBank.scss";
import { postulanteService } from "../../services/postulantJob.service";
import { backendService } from "../../services/backend.service";
import { SelectionState } from "draft-js";

export function DetailsJobs() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [job, setJob] = useState({});
  const [showApplicantForm, setShowApplicantForm] = useState(false);
  const [hasAlreadyApplied, setHasAlreadyApplied] = useState(true);
  const [user, setUser] = useState();
  let { id } = useParams();

  useEffect(() => {
    getJobsById();
    verifyPostulant();

    backendService.userProfile().then((result) => {
      setUser(result);
    });

  }, []);

  const verifyPostulant = () => {
    postulanteService.verifyPostulant(id).then((result) => {
      if(result === "False")
        setHasAlreadyApplied(false);
      else
        setHasAlreadyApplied(true);
    })
  }

  const getJobsById = () => {
    JobBankService.getJobById(id).then((result) => {

      JobBankService.getJobSections().then(sections => {
        let sectionsDescriptions = result.jobSection.map(jobSection => {
          let nameSection = sections.find(section => section.jobSectionTypeId === jobSection.jobSectionTypeId).name;
          return "<h6>" + nameSection + ":</h6>" + jobSection.description;
        });

        console.log(sectionsDescriptions);

        result.description += sectionsDescriptions.join("");
        setJob(result);
      });
    });
  }

  const authorize = (e) => {
    JobBankService.authorizeJob(e.jobId, true).then((result) => {
      Swal.fire({text: "El trabajo ha sido aprobado", icon: "success"});
      getJobsById();
    });
  }

  const reject = (e) => {
    JobBankService.authorizeJob(e.jobId, false).then((result) => {
      Swal.fire({text: "El trabajo ha sido rechazado", icon: "success"});
      getJobsById();
    });
  }

  const closeForm = () => {
    setShowApplicantForm(false);
    verifyPostulant();
  }

  return (
    <section className="wrapper_jobbank padd">
      <TtlsJobBank tituloInJobs="Bolsa de trabajo" />
      <section className="contain_gral_jobbank">
        {!showApplicantForm && 
          <>
            <div className="sec_catalog_listjobs">
              <div className="wrapper_detailsjobs special">
                <div>
                  <Link to="/job-bank" className="goback trans">
                    <span className="ml-1"><ion-icon name="chevron-back-outline"></ion-icon>  Ir a bolsa de trabajo</span>
                  </Link>
                </div>
                <div className="box_items_jobs mt-3">
                  <article className="item_card_jobs card_shadows">
                    <div className="card_info_jobs">
                      <h5 className="text fnt_medium name_vacant">
                        {job.title}
                      </h5>
                      <div className="name_business">
                        <ion-icon name="business-outline"></ion-icon> {job.companyName}
                      </div>
                      <div className="city_jobs mb-3">
                        <ion-icon name="location"></ion-icon> {job.location}
                      </div>
                      <div className="publication_date">
                        <Moment locale="es" format="DD [de] MMMM YYYY">
                          {job.publicationDate}
                        </Moment>
                      </div>
                      <div className="description_job">
                          <div dangerouslySetInnerHTML={{ __html: job.description }} />
                      </div>
                    </div>
                    <div className="card_options">
                      <img
                        src={job.companyImage}
                        alt={"Logo empresa " + job.companyName}
                      />
                      {job.salary > 0 &&
                        <div className="box_salary">
                          <p className="text-muted m-0">Oferta salarial</p>
                          <div className="monto_salary text fnt_medium">
                            ${job.salary} MNX
                          </div>
                        </div>
                      }
                      <div className="box_btns_actions_item">
                        {roleName === "Usuario" && job.status !== "Guardado" && job.createUser !== localStorage.getItem("userId") && !hasAlreadyApplied &&
                          <button className="btn primary " onClick={() => setShowApplicantForm(true)}>
                            Aplicar ahora
                          </button>
                        }
                        {roleName === "Recursos Humanos" && job.status === "Guardado" &&
                          <button className="btn primary b_autorizar"  onClick={() => authorize(job)}>
                            Autorizar
                          </button>}
                        {roleName === "Recursos Humanos" && job.status === "Guardado" &&
                          <button className="btn primary cancel"  onClick={() => reject(job)}>
                            Rechazar
                          </button>}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </>
        }
        {showApplicantForm && job && <ApplicantForm closeForm={closeForm} job={job} user={user}/>}
      </section>
    </section>
  );
}
