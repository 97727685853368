import { setCartQuantity } from "../store/actions";
import store from "../store/configureStore";

export const cartHook = {
  assembleCart,
  getCart,
  addItem,
  onChangeQuantity,
  deleteItem,
  deleteItemDetail,
  getItems,
  clean,
};

const initialCart = { cart: [], total: 0, quantity: 0 };
let cartName = "_cpride";

function assembleCart(isCaringCommunity) {
  setCartName(isCaringCommunity);
  if (
    localStorage.getItem(cartName) === undefined ||
    localStorage.getItem(cartName) === null
  )
    localStorage.setItem(cartName, JSON.stringify(initialCart));

  setQuantity();
}

function getCart() {
  const cpride = JSON.parse(localStorage[cartName]);
  return cpride;
}

async function addItem(product, provider) {
  let cpride = JSON.parse(localStorage[cartName]);

  let item = {
    ProductId: product.productId,
    Name: product.name,
    Price: product.productPrice,
    Quantity: 1,
    Code: product.productCode,
    UrlImage: provider.urlLogoClrs,
    Descripcion: product.description,
    ProviderId: product.providerId,
    Total: product.productPrice,
  };

  let foundProvider = cpride.cart.find(
    (p) => p.ProviderId == provider.providerId
  );
  if (foundProvider != undefined) {
    let isNew = true;

    let foundProduct = foundProvider.Items.find(
      (p) => p.ProductId == item.ProductId
    );
    if (foundProduct != undefined) {
      foundProduct.Quantity += item.Quantity;
      foundProduct.Total = foundProduct.Quantity * item.Price;
      isNew = false;
    }

    if (isNew) foundProvider.Items.push(item);

    foundProvider.Total += item.Total;
    foundProvider.Quantity += item.Quantity;
  } else {
    let itemProvider = {
      ProviderId: provider.providerId,
      ProviderName: provider.name,
      Logo: provider.urlLogoClrs,
      Total: item.Price,
      Quantity: item.Quantity,
      Items: [item],
    };

    cpride.cart.push(itemProvider);
  }

  cpride.total += item.Price;
  cpride.quantity += 1;
  localStorage[cartName] = JSON.stringify(cpride);
  setQuantity(cpride.quantity);
  return cpride;
}

async function onChangeQuantity(providerId, productId, value) {
  let cpride = JSON.parse(localStorage[cartName]);

  let foundProvider = cpride.cart.find((p) => p.ProviderId == providerId);
  if (foundProvider != undefined) {
    let foundProduct = foundProvider.Items.find(
      (p) => p.ProductId == productId
    );
    if (foundProduct != undefined) {
      foundProvider.Quantity -= foundProduct.Quantity;
      cpride.quantity -= foundProduct.Quantity;

      foundProvider.Total -= foundProduct.Total;
      cpride.total -= foundProduct.Total;

      foundProduct.Quantity = value;
      foundProduct.Total = foundProduct.Quantity * foundProduct.Price;

      foundProvider.Quantity += foundProduct.Quantity;
      cpride.quantity += foundProduct.Quantity;

      foundProvider.Total += foundProduct.Total;
      cpride.total += foundProduct.Total;
    }
  }

  localStorage[cartName] = JSON.stringify(cpride);
  setQuantity(cpride.quantity);
  return cpride;
}

async function deleteItem(providerId) {
  let cpride = JSON.parse(localStorage[cartName]);

  let foundProvider = cpride.cart.find((p) => p.ProviderId == providerId);
  if (foundProvider != undefined) {
    cpride.total -= foundProvider.Total;
    cpride.quantity -= foundProvider.Quantity;
    cpride.cart = cpride.cart.filter((p) => p.ProviderId != providerId);
  }

  localStorage[cartName] = JSON.stringify(cpride);
  setQuantity(cpride.quantity);
  return cpride;
}

async function deleteItemDetail(providerId, productId) {
  let cpride = JSON.parse(localStorage[cartName]);

  let foundProvider = cpride.cart.find((p) => p.ProviderId == providerId);
  if (foundProvider != undefined) {
    let foundProduct = foundProvider.Items.find(
      (p) => p.ProductId == productId
    );
    if (foundProduct != undefined) {
      foundProvider.Quantity -= foundProduct.Quantity;
      cpride.quantity -= foundProduct.Quantity;

      foundProvider.Total -= foundProduct.Total;
      cpride.total -= foundProduct.Total;
      foundProvider.Items = foundProvider.Items.filter(
        (p) => p.ProductId != productId
      );

      if (foundProvider.Total == 0)
        cpride.cart = cpride.cart.filter((p) => p.ProviderId != providerId);
    }
  }

  localStorage[cartName] = JSON.stringify(cpride);
  setQuantity(cpride.quantity);
  return cpride;
}

function getItems() {
  const cpride = JSON.parse(localStorage[cartName]);

  let items = [];
  cpride.cart.map((provider) => {
    provider.Items.map((itemtemp) => {
      let item = {
        ProductId: itemtemp.ProductId,
        ProductPrice: itemtemp.Price,
        Cant: itemtemp.Quantity,
      };
      items.push(item);
    });
  });

  return items;
}

async function clean(isCaringCommunity) {
  setCartName(isCaringCommunity);
  localStorage.setItem(cartName, JSON.stringify(initialCart));
  setQuantity(0);
}

const setQuantity = (quantity) => {
  if (quantity) return store.dispatch(setCartQuantity(quantity));

  const cpride = JSON.parse(localStorage[cartName]);
  store.dispatch(setCartQuantity(cpride.quantity));
};

const setCartName = (isCaringCommunity) => {
  cartName = !isCaringCommunity ? "_cpride" : "_cdpride";
};
