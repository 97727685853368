import React, { useState, useEffect } from "react";
import { directoryInfoService } from "../../services/directoryService.service";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { companyCustomizeService } from "../../services/backoffice/companyCustomize.service";

export function ItemDirectory({
  directoryItem,
  isAdmin,
  setdirectoryInfoToAuthorize,
  profileId,
  sectionOn,
  updateListP,
  updateListA,
}) {
  const minValue = 1;
  const maxValue = 5;
  const [estrellas, setEstrellas] = useState([0, 0, 0, 0, 0]);
  const [avrScore, setAvrScore] = useState(0);
  const [avrText, setAvrText] = useState(0);
  const [avrScoreA, setAvrScoreA] = useState(0);
  const [avrScoreB, setAvrScoreB] = useState(0);
  const [avrScoreC, setAvrScoreC] = useState(0);
  const [edit, setEdit] = useState(true);
  const [editA, setEditA] = useState(true);
  const [editB, setEditB] = useState(true);
  const [editC, setEditC] = useState(true);
  const [isEval, setIsEval] = useState(false);
  const [evalA, setEvalA] = useState(0);
  const [evalB, setEvalB] = useState(0);
  const [evalC, setEvalC] = useState(0);
  const [dataCompany, setDataCompany] = useState({});

  const [activeBoxTooltip, setBoxTooltip] = useState("");

  const showBoxTooltip = () => {
    setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  };

  const directoryContacts = directoryItem.directoryContacts;
  const directoryRatings = directoryItem.directoryRatings;

  let phoneContact = {};
  let emailContact = {};
  let socialNetworks = [];
  if (directoryContacts && directoryContacts.length > 0) {
    phoneContact = directoryContacts.find(
      (element) => element.contactTypeName == "Teléfono"
    );
    emailContact = directoryContacts.find(
      (element) => element.contactTypeName == "Email"
    );
    socialNetworks = directoryContacts.filter(
      (element) =>
        element.contactTypeName !== "Email" &&
        element.contactTypeName !== "Teléfono"
    );
  }

  function getDataCompany() {
    companyCustomizeService
      .getByCompanyId(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result && result != null) {
          var object = JSON.parse(result.customizeValues);
          setDataCompany({
            NoPost: object.NoPost,
            NoDaysGrace: object.NoDaysGrace,
          });
        }
      });
  }

  useEffect(() => {
    if (directoryRatings && directoryRatings.length > 0) {
      var sum = directoryRatings.reduce(function (prev, current) {
        return prev + +current.score;
      }, 0);

      var average = sum / directoryRatings.length;
      var averagefloor = Math.floor(average);

      setAvrText(average.toFixed(1));
      setAvrScore(directoryRatings.length);

      var serviceRating = directoryRatings.filter(
        (item) => 1 == item.directoryRatingTypeId
      );
      var priceRating = directoryRatings.filter(
        (item) => 2 == item.directoryRatingTypeId
      );
      var qualityRating = directoryRatings.filter(
        (item) => 3 == item.directoryRatingTypeId
      );

      var uno = directoryRatings.filter((item) => 1 == item.score).length;
      var dos = directoryRatings.filter((item) => 2 == item.score).length;
      var tres = directoryRatings.filter((item) => 3 == item.score).length;
      var cuatro = directoryRatings.filter((item) => 4 == item.score).length;
      var cinco = directoryRatings.filter((item) => 5 == item.score).length;

      setEstrellas([uno, dos, tres, cuatro, cinco]);

      if (serviceRating.length > 0) {
        var service = infoType(serviceRating);
        setAvrScoreA(service[0]);
        setEditA(service[1]);
        setEvalA(service[2]);
      }
      if (priceRating.length > 0) {
        var service = infoType(priceRating);
        setAvrScoreB(service[0]);
        setEditB(service[1]);
        setEvalB(service[2]);
      }
      if (qualityRating.length > 0) {
        var service = infoType(qualityRating);
        setAvrScoreC(service[0]);
        setEditC(service[1]);
        setEvalC(service[2]);
      }
    }

    getDataCompany();
  }, []);
  useEffect(() => {
    if (!editA && !editB && !editC) {
      setEdit(false);
    }
  }, [editA, editB, editC]);

  const infoType = (item) => {
    var data = [0, true, 0];
    var sum = item.reduce(function (prev, current) {
      return prev + +current.score;
    }, 0);
    var average = sum / item.length;
    var averagefloor = Math.floor(average);
    data[0] = averagefloor;
    var userScore = item.filter((item) => profileId == item.userProfileAsp);
    if (userScore.length > 0) {
      data[1] = false;
      data[2] = userScore[0].score;
    }
    return data;
  };

  const approveService = (directoryItem) => {
    swal({
      text: "¿Desea aprobar este directorio?",
      icon: "info",
      buttons: { approve: "Aprobar", cancel: "Cancelar" },
    }).then((button) => {
      if (button === "approve") {
        directoryInfoService
          .approveDirectoryGrace(
            directoryItem.directoryInfoId,
            directoryItem.creatorEmail,
            dataCompany.NoPost,
            dataCompany.NoDaysGrace
          )
          .then((response) => {
            if (response)
              swal({
                text: "Directorio aceptado exitosamente",
                icon: "success",
              });
            updateListP();
            updateListA();
          });
      }
    });
  };

  const rejectService = (directoryItem) => {
    swal({
      text: "¿Desea rechazar este directorio?",
      icon: "info",
      buttons: { reject: "Rechazar", cancel: "Cancelar" },
    }).then((button) => {
      if (button === "reject") {
        directoryInfoService
          .rejectDirectory(directoryItem.directoryInfoId)
          .then((response) => {
            if (response) {
              directoryInfoService
                .getAllByCompany(process.env.REACT_APP_COMPANY, 1)
                .then((dataToAuth) => {
                  setdirectoryInfoToAuthorize(dataToAuth);
                  swal({
                    text: "Directorio rechazado exitosamente",
                    icon: "success",
                  });
                  updateListP();
                });
            }
          });
      }
    });
  };

  function insertScore(score, type) {
    var data = new FormData();
    data.append("DirectoryRatingId", 0);
    data.append("DirectoryInfoId", directoryItem.directoryInfoId);
    data.append("DirectoryRatingTypeId", type);
    data.append("UserProfileId", 0);
    data.append("UserProfileAsp", profileId);
    data.append("Score", score);
    data.append("Active", true);

    directoryInfoService.setDirectoryScore(data).then((response) => {
      if (response) {
        if (type == 1) {
          setEditA(false);
        }
        if (type == 2) {
          setEditB(false);
        }
        if (type == 3) {
          setEditC(false);
        }
        return true;
      }
    });
    return false;
  }

  return (
    <div className=" col-lg-3  col-6 mb-4">
      <div className="card shadows">
        <Link
          to={{
            pathname: !sectionOn
              ? `/register-services/${directoryItem.directoryInfoId}`
              : `/details-directory/${directoryItem.directoryInfoId}`,
            state: { Item: directoryItem, profileId: profileId },
          }}
        >
          <div className="box_content_img">
            <img
              src={directoryItem.contactImage}
              alt={directoryItem.fullName}
            />
          </div>
          <div className="card-content text-center">
            <h6 className="card-title mb-0 text fnt_medium name_directory">
              {directoryItem.fullName}
            </h6>
            {directoryItem.directoryDescription ? (
              <h6 className="card-text mb-0 text fnt_medium name_directory">
                {directoryItem.directoryDescription}
              </h6>
            ) : (
              <h6 className="card-text mb-0 text fnt_medium name_directory">
              </h6>
            )}
            {directoryItem.createUserName ? (
              <h6 className="card-text mb-0 text fnt_medium name_directory">
                {directoryItem.createUserName}
              </h6>
            ) : (
              ""
            )}
            <div className="ttls_direct card-text mb-1 col c_primary name__categ">
              <span className="itemcat">
                {directoryItem.categoryServiceName}{" "}
              </span>
              <span className="itemsubcat">
                {directoryItem.subCategoryServiceName}
              </span>
            </div>

            <div className="card-text mt-1 mb-0">{directoryItem.location} </div>
            <div className="card-text text-center mb-2 mt-2">
              <i className="c_yellow fa-solid fa-star"></i> Ranking {avrText} de
              5
            </div>
          </div>
        </Link>
        {isAdmin && directoryItem.directoryInfoStatusName == "En trámite" ? (
          <>
            <div className="mt-1">
              <button
                className="btnconfirmar"
                onClick={() => approveService(directoryItem)}
              >
                <i className="ri-check-fill"></i> Autorizar
              </button>
            </div>
            <div className="mt-1">
              <button
                className="btnRechazar"
                onClick={() => rejectService(directoryItem)}
              >
                <i className="fa fa-times" aria-hidden="true"></i>Rechazar
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
