import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";


export const companyPostCommentService = {
    create,
    newComment,
    getById,
    newSubComment,
    reportComment,
    getReportById,
    deleteComment
};

async function create(params) {
    return callApi("/backend/PostComment", "POST", params).then(
        (result) => {
            if (result.responseCode == "00") {
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        }
    );
}

async function newComment(params) {
    return callApi("/backend/PutComment", "PUT", params).then(
        (result) => {
            if (result.responseCode == "00") {
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        }
    );
}

async function reportComment(TypeId, IdPadre, IdHijo) {
    return callApi("/backend/PostComment/" + TypeId + "/" + IdPadre + '/' + IdHijo, "delete").then(
        (result) => {
            if (result.responseCode == "00") {
                return result.data;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return null;
            }
        }
    );
}

async function deleteComment(IdPadre, IdHijo) {
    return callApi("/backend/PostComment/Delete/" + IdPadre + '/' + IdHijo, "delete").then(
        (result) => {
            if (result.responseCode == "00") {
                return result.data;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return null;
            }
        }
    );
}

async function newSubComment(params, id) {
    return callApi("/backend/PutComment/Sub/" + id, "PUT", params).then(
        (result) => {
            if (result.responseCode == "00") {
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        }
    );
}

async function getById(companyPostId) {
    return callApi("/backend/PostComment/" + companyPostId, "GET").then(
        (result) => {
            if (result.responseCode == "00") {
                return result.data;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return null;
            }
        }
    );
}

async function getReportById(companyPostCommentId) {
    return callApi("/backend/PostComment/Report/" + companyPostCommentId, "GET").then(
        (result) => {
            if (result.responseCode == "00") {
                return result.data;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return null;
            }
        }
    );
}