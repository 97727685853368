import React, { useState } from 'react'
import { Tooltip } from 'reactstrap';
import './rewards.scss'
import { rewardsService } from "../../services/rewards.service";
import swal from "sweetalert"

export function ItemRewardCupon(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const redeemCoupon = () => {
        swal({
            title: "¿Estás seguro que deseas obtener este cupón?",
            text: props.title + " - " + props.points + " Puntos",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    rewardsService.redeemCoupon({ RewardProductFilterId: props.rewardProductFilterId })
                        .then((result) => {
                            if (result) {
                                props.setCategoryActive(0);
                                props.getRewardProducts(0);
                            }
                        });
                }
            });
    }

    return (
        <div className="box_item_cupon">
            <div className="item_cupon">
                <div className="box_img_cupon">
                    <img className="trans" src={props.urlImage} alt="product" />
                </div>
                <div className="info_cupon">
                    <div>
                        <p className="m-0 name_categ">{props.rewardCategoryDescription}</p>
                        <p className="name_cupon">{props.title}</p>
                        <p className="name_cupon">{props.points} Puntos</p>
                    </div>
                    {
                        props.reward >= props.points ?
                            <div>
                                <div
                                    className="ico_download"
                                    id={"Tooltip" + props.rewardProductFilterId}
                                    onClick={redeemCoupon}
                                >
                                    <span >
                                        <i className="trans far fa-arrow-alt-circle-down"></i>
                                        <i className="trans fas fa-arrow-alt-circle-down"></i>
                                    </span>
                                </div>
                                <Tooltip placement="bottom" isOpen={tooltipOpen} target={"Tooltip" + props.rewardProductFilterId} toggle={toggle} >
                                    Descargar
                                </Tooltip>
                            </div>
                            : null
                    }


                </div>
            </div>
        </div>

    )
}