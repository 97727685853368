import React, { Component } from 'react';
import BoxPayMethod from '../../components/boxPayMethod/boxPayMethod'
import { Link } from 'react-router-dom';

export class PaymentCanceled extends Component {
	static displayName = PaymentCanceled.name;

	render() {
		return (
			<div className="wrapping_pay_canceled">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6 text-center">
							<div className="box_cancel">
								<p className="cancel_label">CANCELACIÓN</p>
								<div className="cancel_box_icons">
									<span><i className="far fa-frown"></i></span>
									<h1>¡Oops!</h1>
								</div>
								<div className="cancel_info">
									<h4 className="text fnt_medium">Algo salió mal con tu orden</h4>
									<p className>Ocurrió un problema con el pago de tu orden, ha sido cancelada o rechazada por el banco.</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 text-center">
							<div className="box_new_try">
								<div className="new_try">
									<h4 className="text fnt_medium c_green">Intentémos de nuevo</h4>
									<p>¿Quieres volver a intentar con otra tarjeta u otra forma de pago?</p>
									<BoxPayMethod />
								</div>
								<div className="new_try">
									{/*<p className="m-0">o ¿Quieres guardar tu compra para más tarde?</p>
                  <p><small className="c_primary">Recuerda que tienes 12hrs. para volver por tu compra</small></p>*/}
								</div>
								<div className="text-center box_actions_pay">
									<Link className="btn primary " to="/home">Ir a inicio</Link>
									{/*<Link className="btn rb_go" to="/shopping">Si, guardar compra</Link>*/}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}
