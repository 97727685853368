import React, { useEffect, useState } from "react";
import "./audience.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { BoxListAudience } from "./boxListAudience";
import { audienceService } from "../../services/audience.service";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";
import PictureHelp from "../../assets/imgs/picture_audience.png";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import moment from "moment";

export function Audience(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [listAudienceP, setListAudienceP] = useState([]);
  const [listAudienceO, setListAudienceO] = useState([]);

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  async function getPending() {
    audienceService.getPending().then((result) => {
      if (result) {
        setListAudienceP(result);
      }
    });
  }

  async function getComplete() {
    audienceService.getComplete().then((result) => {
      if (result) {
        setListAudienceO(result);
      }
    });
  }

  useEffect(() => {
    getPending();
    getComplete();
  }, []);

  return (
    <section className="wrapper_surveys wrapper_audience padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">
               TLC talks
            </h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date()}
              </Moment>
            </span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
        </div>
        <AdvertisingSection HolType={4} />

        {/* <BoxListAudience /> */}
        {listAudienceP.length > 0 || listAudienceO.length > 0 ? (
          <BoxListAudience
            Title="Disfruta de videos y programas que te enriquecerán"
            Items={listAudienceP}
            OldItems={listAudienceO}
          />
        ) : null}

        {/* === TRANSMISIONES PASADOS === */}
        {/* <div className="old_transmitions">
          <hr />

          <BoxListAudience
            Items={listAudienceP}
            Title="Transmisiones pasadas"
          />
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}

        <p className="text-muted line_height">
          <small>
            La entrada a estas transmisiones considera el proceso de admisión de
            RedAccess.
          </small>
        </p>
      </div>

      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <SideAdvertising />
      </aside>
    </section>
  );
}
