import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardProviderService } from '../../services/control/rewardProvider.service';

import { Link } from 'react-router-dom';

export function EditRewardProvider(props) {

    const onSave = () => {
        // debugger
        if (inputs.rewardProviderId && inputs.rewardProviderId > 0)
            rewardProviderService.edit(inputs);
        else
            rewardProviderService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardProviderId: 0,
        businessName: "",
        urlLogo: "",
        active: true,
        logoImage: null
    });

    useEffect(() => {
        if (props.rewardProviderId != 0) {
            rewardProviderService.getById(props.rewardProviderId).then((result) => {
                setReward({
                    rewardProviderId: result.rewardProviderId,
                    businessName: result.businessName,
                    urlLogo: result.urlLogo,
                    active: result.active,
                    logoImage: null
                });
            });
        }
    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);

    return (
        <div className="wrapper_form_reward card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.rewardProviderId != 0 ? "Editar proveedor" : "Nuevo proveedor"}</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <input type="hidden" name="rewardCategoryId" onChange={handleInputChange} value={inputs.rewardProviderId} />
                            <div className="form-group">
                                <label htmlFor="businessName">Nombre</label>
                                <input type="text" name="businessName" className="form-control" onChange={handleInputChange} value={inputs.businessName} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="logoImage">Logotipo</label>
                                <input type="file" name="logoImage" className="form-control" onChange={handleInputFileChange} {...(props.rewardProviderId == 0 && { required: true })} />
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    name="active"
                                    id="active"
                                    className="custom-control-input"
                                    onChange={handleInputChange}
                                    checked={inputs.active} />
                                <label htmlFor="active" className="custom-control-label">Activo</label>
                            </div>
                        </div>
                    </div>*/}
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/controlreward/2" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   