import React, { useState, useEffect } from "react";
import { companyPostService } from "../../services/companyPost.service";
import Gallery from "react-grid-gallery";

export function CompanyGallery({ item }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (item != null && item.companyPostId != null && item.companyPostId > 0) {
      companyPostService
        .getCompanyPostImagesPathByIdFront(item.companyPostId)
        .then((result) => {
          let imagesList = [];
          result.files.map((item) =>
            imagesList.push({
              src: item.path,
              thumbnail: item.path,
            })
          );
          setImages(imagesList);
        });
    }
  }, [item]);

  return (
    <Gallery
      images={images}
      enableImageSelection={false}
      backdropClosesModal={true}
    />
  );
}
