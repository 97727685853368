import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { rewardCategoryService } from '../../services/control/rewardCategory.service';

export function SearchRewardCategory(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        rewardCategoryService.deleteCategory(id).then((result) => { getRewardcategories(); });
    };

    useEffect(() => {
        getRewardcategories();
    }, [props.refresh]);

    async function getRewardcategories() {
        rewardCategoryService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">

                <thead>
                    <tr>
                        <th className="text fnt_medium">Id
					</th>
                        <th className="text fnt_medium min_width">descripción
					</th>
                        <th className="text fnt_medium">Ícono
					</th>
                        <th className="text fnt_medium">Activo
					</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardCategoryId}>
                            <td>
                                <Link className="link_data" to={"/details-rewardCategory/" + reward.rewardCategoryId}>{reward.rewardCategoryId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-rewardCategory/" + reward.rewardCategoryId}>{reward.description}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-rewardCategory/" + reward.rewardCategoryId}>{reward.urlIcon}</Link>
                            </td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>

                            <td>
                                <span className="icon_tables" onClick={() => handleDelete(reward.rewardCategoryId)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}