export const setCountrySetting = (payload) => {
  return {
    type: "Set_CountrySetting",
    payload,
  };
};

export const setCartQuantity = (payload) => {
  return {
    type: "Set_CartQuantity",
    payload,
  };
};

export const toggleBlocking = (payload) => {
  return {
    type: "Toggle_Blocking",
    payload,
  };
};

export const setUserSettings = (payload) => {
  return {
    type: "Set_UserSettings",
    payload,
  };
};

export const setAccountSettings = (payload) => {
  return {
    type: "Set_AccountSettings",
    payload,
  };
};

export const setRefreshingToken = () => {
  return {
    type: "Set_RefreshingToken",
  };
};
