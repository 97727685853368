import { callApi } from './service'

export const attendeeService = {
    getAttendeeUsers
};

async function getAttendeeUsers(targetId, eventType) {
    return callApi(`/backoffice/Attendee/${targetId}/${eventType}`, 'GET')
        .then((result) => {
            if (result.responseCode == '00') {
                return result.data;
            } else {
                console.log(result.responseMessage);
                return [];
            }
        });
}