import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { deparmentService } from '../../services/deparment.service';


const SearchDeparment = forwardRef((props, ref) => {

	const [dataGrid, setDataGrid] = useState([]);
	const handleDelete = (event, data) => {
		deparmentService.deletetDeparment(data).then((result) => { getDeparment(); });
		event.stopPropagation();
	};

	async function getDeparment() {
		deparmentService.getDeparment()
			.then((result) => {
				if (result) {
					setDataGrid(result);
				}
			});
	}

	useEffect(() => {
		getDeparment();
	}, []);

	const refreshDataGrid = () => {
		getDeparment();
	}

	useImperativeHandle(ref, () => ({
		refreshDataGrid: refreshDataGrid
	}));

	return (

		<div className="table-responsive">
			<table className="wrapper_table table table-sm table-hover">

				<thead>
					<tr>
						<th className="text fnt_medium min_width">Grupo</th>
						<th className="text fnt_medium">Estatus</th>
						<th className="text fnt_medium">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{dataGrid.map(item => (
						<tr key={item.departmentId} >
							<td><span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.departmentName}</span> </td>
							<td>{item.active ? 'Activo' : 'Inactivo'}</td>
							<td>
								{item.active ?
									<span>
								<span className="icon_tables" onClick={() => props.handleRowSelect(item)}>
									<i className="fas fa-pen"></i>
								</span>
								<span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
									<i className="far fa-trash-alt"></i>
										</span>
									</span> : null}

							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>

	);

});

export default SearchDeparment;