import { callApi, callApiFile, callApiFileStorage } from './service'
import swal from "sweetalert"


export const adminAudienceService = {
    create,
    getAll,
    update,
    deletet,
    getAudiceImageById
};


async function getAll() {
    return callApi('/backoffice/Auditorium', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function deletet(params) {
    return callApi('/backoffice/Auditorium', 'DELETE', params);
}

async function create(params, images) {

    var data = new FormData();
    if (images) {
        for (var i = 0; i < images.length; i++) {
            var f = images[i];
            data.append('images', f, f.name);
        }
    }

    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/Auditorium', 'POST', data).then((result) => {
        return handleRsponse(result);
    });
}

async function update(params, images) {
    var data = new FormData();
    if (images) {
        for (var i = 0; i < images.length; i++) {
            var f = images[i];
            data.append('images', f, f.name);
        }
    }
    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/Auditorium', 'PUT', data).then((result) => {
        return handleRsponse(result);
    });
}

async function getAudiceImageById(auditoriumId){
    return callApi('/backoffice/Auditorium/WithImages/' + auditoriumId, 'GET').then((result) => handleRsponse(result));
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        return null;
    }
}