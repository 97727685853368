
import React, { useState, useEffect } from "react";
import { HeadRewards } from './headRewards'
import './rewards.scss'
import { rewardsService } from "../../services/rewards.service";
import { FormGroup, Label, Input } from "reactstrap";
import Moment from 'react-moment'
import moment from 'moment';
import 'moment/locale/es';

export function RedemptionHistory() {
    const [rewardsFilterByDate, setRewardsFilterByDate] = useState([]);
    const [reward, setReward] = useState([]);
    const [redemptionHistory, setRedemptionHistory] = useState([]);

    useEffect(() => {
        rewardsService.rewardsFilterByDate()
            .then((result) => {
                setRewardsFilterByDate(result);
                getRedemptionHistory(result[0].startDate + "|" + result[0].endDate);
            });
    }, []);

    const getRedemptionHistory = async (dateRange) => {
        rewardsService.redemptionHistory(dateRange)
            .then((result) => {
                setReward(result.reward);
                setRedemptionHistory(result.items);
            });
    }

    const downloadCoupon = (orderId) => {
        rewardsService.downloadCoupon(orderId);
    }

    return (
        <section className="wrapper_redemption_history">

            <HeadRewards reward={reward} />

            <div className="padd pt-0">
                <h3 className="text fnt_medium mb-4">Historial de canje</h3>
                <FormGroup>
                    <Label for="exampleSelectMulti" >Filtro de fechas</Label>
                    <Input type="select" onChange={(event) => getRedemptionHistory(event.target.value)}>
                        {
                            rewardsFilterByDate.map(item => <option value={item.startDate + "|" + item.endDate}>{moment(item.startDate).format("MMMM YYYY")} - {moment(item.endDate).format("MMMM YYYY")}</option>)
                        }
                    </Input>
                </FormGroup>
                <div className="row ">
                    <div className="col-12">
                        <div className="wrapper_list_history">
                            {
                                redemptionHistory.map(item =>
                                    <div className="list_history">
                                        <div className="item_list_history">
                                            <img className="img_cupon" src={item.productUrlImage} alt="Cupon" />
                                        </div>
                                        <div className="item_list_history">
                                            <h6 className="text fnt_medium">{item.productTitle}</h6>
                                            <h6 className="text fnt_medium">{item.poductDescription}</h6>
                                        </div>
                                        <div className="item_list_history">
                                            <h6 className="text fnt_medium">Fecha de redención</h6>
                                            <p><Moment format="DD/MM/YYYY">{item.date}</Moment></p>
                                        </div>
                                        <div className="item_list_history">
                                            <h6 className="text fnt_medium">Puntos redimidos</h6>
                                            <p>{item.reward}</p>
                                        </div>
                                        <div className="item_list_history">
                                            <h6 className="text fnt_medium">Acción</h6>
                                            {item.status == 2 ? <p><button className="btn secundary btndown" onClick={() => { downloadCoupon(item.rewardOrderUserProfileId); }} ><i className="fas fa-download"></i> Descargar</button></p> : ""}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
