import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const rewardProviderService = {
    create,
    get,
    getById,
    edit,
    deleteProvider
};

async function create(data) {
    const formData = new FormData();
    formData.append("rewardProviderId", data.rewardProviderId);
    formData.append("businessName", data.businessName);
    formData.append("urlLogo", data.urlLogo);
    formData.append("active", data.active);
    formData.append("logoImage", data.logoImage);
    return callApiFileStorage('/BackOffice/RewardProvider', 'POST', formData, null)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/controlreward/2")
            } else {
                console.log(result.responseMessage);
                swal({ text: "Error al guardar", icon: "error" });
            }
        });
}

async function get() {
    return await callApi('/BackOffice/RewardProvider', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/RewardProvider/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function edit(data) {
    const formData = new FormData();
    formData.append("rewardProviderId", data.rewardProviderId);
    formData.append("businessName", data.businessName);
    formData.append("urlLogo", data.urlLogo);
    formData.append("active", data.active);
    formData.append("logoImage", data.logoImage);
    return callApiFileStorage('/BackOffice/RewardProvider', 'PUT', formData, null)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Se actualizo correctamente", icon: "success" }).then(() => window.location.href = "/controlreward/2")
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function deleteProvider(id) {
    return callApi('/BackOffice/RewardProvider?id=' + id, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "se desactivó correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la categoría", icon: "error" });
            return null;
        }
    });
}


