import React, { useEffect, useState } from "react";
import moment from "moment";
import { ItemInfoPost } from "./itemInfoPost/itemInfoPost";
import { useFrontDiary } from "../services/diary.service";
import "moment/locale/es";

export function BoxSchedule() {
  const [DiaryData, setDiaryData] = useState({ Diary: [] });

  const getCompanyDiary = () => {
    useFrontDiary
      .getFrontCompanyDiaryFiltered(
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        null,
        false
      )
      .then((result) => {
        if (result) {
          result.forEach((d) => {
            d.startDate = new Date(d.startDate);
            d.endDate = new Date(d.endDate);
          });
          setDiaryData({ Diary: result });
        }
      });
  };

  useEffect(() => {
    getCompanyDiary();
  }, []);

  return (
    <div className="box_schedule drop_home shadows bradius">
      <div className="content_drop">
        {DiaryData.Diary.map((item) => (
          <ItemInfoPost key={item.CompanyDiaryId} currentEvent={item} />
        ))}
      </div>
    </div>
  );
}
