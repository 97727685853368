import React, { Fragment, useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import Moment from 'react-moment'
import { DropDownDate } from './dropDownsMessage'
import { useSelector } from 'react-redux';

export default function DraftMessages(props) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [userMessages, setUserMessages] = useState([])
    const [dateFilter, setDateFilter] = useState({ title: 'Descendente', value: 'desc' })
    const deleteMessage = (id) => messageService.deleteMessage(id).then(getMessages)
    const editMessage = (message) => {
        props.setDraft(message)
        props.setIsActive(5)
    }

    useEffect(() => {
        getMessages()
    }, [dateFilter])

    const getMessages = () => {
        messageService.getMessage(3, dateFilter.value, '')
            .then((data) => setUserMessages(data))
    }

    return (
        <Fragment>
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <h3 className="mb-0 text fnt_medium">Borradores</h3>
                    <div className="box_actions">
                        {roleName === "Recursos Humanos" &&
                            <button type="button" className="btn secundary" onClick={() => { props.setIsActive(5); props.setDraft({}) }}>
                                <i className="fas fa-plus"></i> Mensaje nuevo
              </button>
                        }
                    </div>
                </div>
            </div>
            <div className="box_filter_messg">
                <DropDownDate title={dateFilter.title} setFilter={setDateFilter} />
            </div>

            <div className="table-responsive">
                <table className="wrapper_table table  table-hover table-sm">
                    <thead>
                        <tr>
                            <th className="text fnt_medium min_width" scope="col">Asunto</th>
                            <th className="text fnt_medium" scope="col">Fecha</th>
                            <th className="text fnt_medium" scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userMessages.map((message, index) =>
                            <tr key={index} className={`tr_msg ${message.messageDetail && message.messageDetail.isRead ? "is_read" : ""}`}>
                                <td className="link_data td_user" onClick={() => editMessage(message.message)}>{message.message.subject}</td>
                                <td><Moment format="DD/MM/YYYY">{message.message.createDate}</Moment></td>
                                <td>
                                    {/*<span className="icon_tables" onClick={() => deleteMessage(message.message.messageId)}>
                    <i className="far fa-trash-alt"></i>
                  </span>*/}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </Fragment>
    )
}