import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardCategoryService } from '../../services/control/rewardCategory.service';

import { Link } from 'react-router-dom';

export function EditRewardCategory(props) {

    const onSave = () => {
        // debugger
        if (inputs.rewardCategoryId && inputs.rewardCategoryId > 0)
            rewardCategoryService.edit(inputs);
        else
            rewardCategoryService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardCategoryId: 0,
        description: "",
        urlIcon: "",
        active: true
    });

    useEffect(() => {
        if (props.rewardCategoryId != 0) {
            rewardCategoryService.getById(props.rewardCategoryId).then((result) => {
                setReward({
                    rewardCategoryId: result.rewardCategoryId,
                    description: result.description,
                    urlIcon: result.urlIcon,
                    active: result.active
                });
            });
        }
    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);

    return (
        <div className="wrapper_form_reward card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.rewardCategoryId != 0 ? "Editar categoría" : "Nueva categoría"}</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <input type="hidden" name="rewardCategoryId" onChange={handleInputChange} value={inputs.rewardCategoryId} />
                            <div className="form-group">
                                <label htmlFor="description">Descripción</label>
                                <input type="text" name="description" className="form-control" onChange={handleInputChange} value={inputs.description} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="urlIcon">Ícono</label>
                                <input type="text" name="urlIcon" className="form-control" onChange={handleInputChange} value={inputs.urlIcon} required />
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">
                        <div className="col-md-6 col-lg-4">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        name="active"
                                    id="active"
                                        className="custom-control-input"
                                        onChange={handleInputChange}
                                    checked={inputs.active} />
                                <label htmlFor="active" className="custom-control-label">Activo</label>
                                </div>
                        </div>
                    </div>*/}
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/controlreward/3" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   