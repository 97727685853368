import { callApi } from '../service'
import swal from "sweetalert"

export const rewardCategoryService = {
    create,
    get,
    getById,
    edit,
    deleteCategory
};

async function create(data) {
    return callApi('/BackOffice/RewardCategory', 'POST', data)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/controlreward/3")
            } else {
                console.log(result.responseMessage);
                swal({ text: "Error al guardar", icon: "error" });
            }
        });
}

async function get() {
    return await callApi('/BackOffice/RewardCategory', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/RewardCategory/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function edit(data) {
    return callApi('/BackOffice/RewardCategory', 'PUT', data)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Se actualizo correctamente", icon: "success" }).then(() => window.location.href = "/controlreward/3")
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function deleteCategory(id) {
    return callApi('/BackOffice/RewardCategory?id='+id, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "se desactivó correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la categoría", icon: "error" });
            return null;
        }
    });
}


