import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorCommentsBox } from "./editorCommentsBox";
import Moment from "react-moment";
import "moment/locale/es";

export function ItemsComment(props) {
  const [data, setData] = useState({});

  const [showAnswerComment, setShowAnswerComment] = useState("");
  const [showParentAnswer, setShowParentAnswer] = useState("hidden");
  const handleAnswerComment = () => {
    setShowParentAnswer(showAnswerComment === "" ? "" : "hidden");
    setShowAnswerComment(showAnswerComment === "" ? "is-show" : "");
  };

  useEffect(() => {
    if (props.contenido) {
      setData(props.contenido);
      setShowAnswerComment("");
    }
  }, [props]);

  const SubComment = (dtaSub) => {

    if (dtaSub.dataArray.length > 0) {
      return <div className={`answer__in_comment `}>
        {dtaSub.dataArray.map((item) => (
          <>
            <div className="items_comment_mnj cxxxxxc">
              <div className="wrapper__bg_flags">
                <div
                  className={"bgflag " + item.userProfile.identityFlagName}
                ></div>
                <div className="contain_avatar">
                  <div className="box_avatar">
                    <img src={item.userProfile.urlProfileImage} alt="PMX" />
                  </div>
                </div>
              </div>
              <div className="drawer_comment_mnj">
                <div className="comment_mjs">
                  <div className="name_author">
                    {item.userProfile.firstName +
                      " " +
                      item.userProfile.middleName +
                      " " +
                      item.userProfile.lastName}
                  </div>
                  <p>{item.comment}</p>
                </div>
                <div className="options_comment_mnj ">
                  <span className="btn_answer"></span>
                  <span className="spandate">
                    <Moment locale="es" format=" DD [de] MMMM [de] YYYY, h:mm:ss a">
                      {item && item.createDate}
                    </Moment>
                  </span>
                </div>
              </div>
              {props.btnBack || item.isReport ? (<div className="btnx-group">
                <span
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </span>
               
                <div className="dropdown-menu">
                {item.isReport ? (<span className="dropdown-item" onClick={() => props.eliminar(data.companyPostCommentId, item.id)}>Eliminar Mensaje</span>)
                   : (<span className="dropdown-item" onClick={() => props.report(data.companyPostCommentId, item.id)}>Reportar</span>)}
                </div>
              </div>):""}
            </div>           
          </>
        ))}
         <div className={`box_for_comments ${showAnswerComment}`}>
              <EditorCommentsBox
                profileUser={props.profileUser}
                clickSave={props.clickSave}
                id={data.companyPostCommentId}
              />
            </div>
      </div>;
    } else {
      return (
        <div className={`answer__in_comment `} style={{ visibility: showParentAnswer }}>
          <div className={`box_for_comments ${showAnswerComment}`}>
            <EditorCommentsBox
              profileUser={props.profileUser}
              clickSave={props.clickSave}
              id={data.companyPostCommentId}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="wrapper__items_comments">
      <div className="items_comment_mnj">
        <div className="wrapper__bg_flags">
          <div
            className={
              "bgflag " +
              (data.userProfile ? data.userProfile.identityFlagName : "")
            }
          ></div>
          <div className="contain_avatar">
            <div className="box_avatar">
              <img
                src={data.userProfile ? data.userProfile.urlProfileImage : ""}
                alt="PMX"
              />
            </div>
          </div>
        </div>
        <div className="drawer_comment_mnj">
          <div className="comment_mjs">
            <div className="name_author">
              {data.userProfile
                ? data.userProfile.firstName +
                " " +
                data.userProfile.middleName +
                " " +
                data.userProfile.lastName
                : ""}
            </div>
            <p>{data ? data.history : ""}</p>
          </div>
          <div className="options_comment_mnj">
          {props.btnBack ? (<span className="btn_answer" onClick={handleAnswerComment}>
              Responder
            </span>):""}
            <span className="spandate">
              <Moment locale="es" format=" DD [de] MMMM [de] YYYY, h:mm:ss a">
                {data && data.createDate}
              </Moment>
            </span>
          </div>
        </div>
        {props.btnBack || data.isReport ? (<div className="btnx-group">
                <span
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </span>
               
                <div className="dropdown-menu">
                  {data.isReport ? (<span className="dropdown-item" onClick={() => props.eliminar(data.companyPostCommentId)}>Eliminar Mensaje</span>)
                   : (<span className="dropdown-item" onClick={() => props.report(data.companyPostCommentId)}>Reportar</span>)}
                </div>
              </div>):""}
      </div>
      {data.comments ? <SubComment dataArray={data.comments} /> : ""}
    </div>
  );
}
