import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { companyPostService } from "../../services/companyPost.service";
import { storageService } from "../../services/storage.service";
import { Modal } from "reactstrap";
import ReactPlayer from "react-player";

export function CompanyPostAttachment(props) {
  const [localStorage, setlocalStorage] = useState([]);

  async function getAttachments() {
    if (props.CompanyPostId != null && props.CompanyPostId > 0) {
      companyPostService
        .getCompanyPostFilesById(props.CompanyPostId)
        .then((result) => {
          if (result != null && result.files != null) {
            setlocalStorage(result.files);
          }
        });
    }
  }

  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  // const [fileName, setFileName] = useState("");
  // const [fileType, setFileType] = useState("pdf");

  useEffect(() => {
     getAttachments();
  }, [props.CompanyPostId]);

  const handleDelete = (event, data) => {
    storageService
      .deleteStorage({
        EntityId: data.entityId,
        EntityTypeId: data.entityTypeId,
        Sequence: data.sequence,
      })
      .then((result) => {
        setlocalStorage(
          localStorage.filter(function (storage) {
            return storage.sequence !== data.sequence;
          })
        );
      });
    event.stopPropagation();
  };

  return (
   <>
   <span>
      {localStorage != null && localStorage.length > 0 ? (
        <span className="files">
          {localStorage.map((item) => (
            <span>
              <a
                className="item_file"
                key={
                  item.entityId + "-" + item.entityTypeId + "-" + item.sequence
                }
                download={item.fileName}
                href={`data:application/octet-stream;base64,${item.file}`}
                
                // onClick = {()=>{
                //   console.log(item);
                //   setModal(true); 
                //   setFileName( "https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/"+item.path+".pdf");
                // }}
              >
                <span className="ico_file">
                  <i className="far fa-file-alt"></i>
                </span>
                <small>{item.fileName}</small>
              </a>
              {props.IsDelete != null && props.IsDelete ? (
                <span
                  className="trans btn_delete"
                  onClick={(event) => handleDelete(event, item)}
                >
                  <i className="far fa-trash-alt"></i>
                </span>
              ) : null}
            </span>
          ))}
        </span>
      ) : null}
    </span>
    {/* <Modal isOpen={modal} toggle={toggle} className="wrapper_modal_cap">
    {
      {
        1: (
          <div>
            <div className="toolbar_opt_modal">
              <span className="btns_modal text fnt_bold" onClick={toggle}>
                <i className="far fa-window-close"></i> <span>CERRAR</span>
              </span>
            </div>
            {
              {
                pdf: <iframe src={fileName}></iframe>,
                mp4: (
                  <ReactPlayer
                    url={fileName}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                ),
              }[fileType]
            }
          </div>
        ),            
      }[setlocalStorage.type]
    }
  </Modal> */}
  </>
  );
}
