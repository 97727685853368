import { callApi } from "../services/service";
import { setRefreshingToken } from "../store/actions";
import store from "../store/configureStore";

export const validateToken = (token) => {
  if (!token.access && !token.refresh) return (window.location.href = "/login");
  const currentDate = new Date();
  const refreshAt = new Date(token.refreshAt);
  if (currentDate > refreshAt) {
    const expireAt = new Date(token.expireAt);
    refreshToken(token.refresh, currentDate > expireAt);
  }
};

export const refreshToken = async (refreshToken, reload) => {
  const refreshingToken = store.getState().refreshingToken;
  if (!refreshingToken) {
    store.dispatch(setRefreshingToken());
    callApi(`/auth/refreshtoken`, "PUT", undefined, {
      SkipValidationToken: true,
      NoBlocking: !reload,
      RefreshToken: refreshToken,
    }).then((result) => {
      store.dispatch(setRefreshingToken());
      if (result.responseCode === "00") {
        setToken(result.data);
        if (reload) window.location.reload();
      } else {
        window.location.href = "/login";
      }
    });
  }
};

export const setToken = (data) => {
  const expireAt = new Date();
  expireAt.setMinutes(expireAt.getMinutes() + data.expiresIn);

  const refreshAt = new Date();
  refreshAt.setMinutes(refreshAt.getMinutes() + (data.expiresIn - 2));

  const token = {
    access: data.token,
    refresh: data.refreshToken,
    expireAt: expireAt,
    refreshAt: refreshAt,
  };

  localStorage["_tpride"] = JSON.stringify(token);
  localStorage["userId"] = data.userId;
  localStorage["roleName"] = data.roleName;
  localStorage.removeItem("isFirstTime");
};

export const getToken = (data) => {
  if (localStorage["_tpride"]) return JSON.parse(localStorage["_tpride"]);
  else
    return {
      access: "",
      refresh: "",
      expireAt: "",
      refreshAt: "",
    };
};
