import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useSurveyAnswerType } from '../../services/surveyAnswerType.service'

export function EditAnswerType(props) {
	const onSave = () => {
		if (inputs.SurveyAnswerTypeId && inputs.SurveyAnswerTypeId > 0)
			useSurveyAnswerType.updateSurveyAnswerType({ SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Description: inputs.Description }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
		else
			useSurveyAnswerType.createSurveyAnswerType({ Description: inputs.Description }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
	};
	const handleOnCancel = () => {
		if (props.onCancelEdit)
			props.onCancelEdit();
	};
	const [survey, setSurvey] = useState({ SurveyAnswerTypeId: "", Description: "" });
	const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, survey);

	useEffect(() => {
		let newP = {};
		if (props.currentAnswerType) {
			newP = { SurveyAnswerTypeId: props.currentAnswerType.SurveyAnswerTypeId.toString(), Description: props.currentAnswerType.Description };
			setSurvey(newP);
		}
		else
			setSurvey({ SurveyAnswerTypeId: "", Description: "" });
	}, [props.currentAnswerType]);

	return (
		<div className="row">
			<div className="col-md-8">
				<form onSubmit={handleSubmit}>
					<div>
						<input type="hidden" name="SurveyAnswerTypeId" onChange={handleInputChange} value={inputs.SurveyAnswerTypeId} />
						<div className="form-group">

							<label>Tipo de respuesta</label>
							<input type="text" className="form-control" name="Description" onChange={handleInputChange} value={inputs.Description} required />
						</div>
					</div>
					<div className="mt-4">
						<button className="btn minimum ml-1 mr-1 secundary" type="button" onClick={handleOnCancel}>Cancelar</button>
						<button className="btn minimum primary ml-1 mr-1" type="submit">Guardar</button>
					</div>
				</form>
			</div>
		</div>
	);
}   