import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/R_C.png";
import "./register.scss";
import { userService } from '../../services/user.service'
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache"

export function VerifyAccount(props) {
    const companyLogo = useSelector(state => state.userSettings.companyLogo);
    const [verify, setVerify] = useState();

    useEffect(() => {
        userService.verifyAccount({ UserId: props.location.search.split("userId=")[1].split("&")[0], Code: props.location.search.split("code=")[1] })
            .then(result => setVerify(result));
    }, [props.location.search]);

    return (
        <section className="wrapper_register">
            <img
                className="bg_register"
                src={bgRegister}
                alt="Resgistrate en Kuimby Social"
            />
            <div className="container container_register">
                <div className="drawer_card_register">
                    <div className="box_brand_register">
                        <div>
                            <h2 className="m-0 text fnt_bold c_primary">Verificación de cuenta</h2>
                        </div>
                        <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Logo oficial Kuimby Social" />
                    </div>
                    <hr />
                    {verify &&
                        <div className="box_form_register pay_success">
                            <div className="box_form_membership">
                                <div className="row">
                                    {verify.responseCode == "00" ?
                                        <div className="col-md-12 mt-2">
                                            <div className=" text-center box-ico-pay success">
                                                <i className="far fa-check-circle"></i>
                                            </div>
                                            <h2 className="ttl_membership text-center text fnt_bold line">
                                                ¡Verificación exitosa!
                                             </h2>
                                        </div>
                                        : <div className="col-md-12 mt-2">
                                            <div className=" text-center box-ico-pay cancel">
                                                <i className="fas fa-info-circle"></i>
                                            </div>
                                            <h2 className="ttl_membership text-center text fnt_bold line">
                                                ¡Error al verificar cuenta!
                                            </h2>
                                            <p className="paragraph ">
                                                {verify.responseMessage}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className="box_form_btns text-center mt-2">
                                <Link to="/login" className="btn primary">
                                    Iniciar sesión
                            </Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}
