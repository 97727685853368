import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Moment from "react-moment";
import { useSurvey } from '../../services/survey.service';

export function SearchSurvey(props) {
	const [surveysData, setSurveysData] = useState({ surveys: [] });
	const handleDelete = (data) => {
		useSurvey.deleteSurvey(data).then((result) => { getSurveys(); });
	};
	const [selectedSurvey, setSelectedSurvey] = useState();

	let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

	useEffect(() => {
		getSurveys();
	}, [props.refresh]);

	async function getSurveys() {
		useSurvey.getSurveys().then((result) => {
			if (result) {
				setSurveysData({ surveys: result });
			}
		});
	}

	return (
		<div className="table-responsive card shadows p-3">
			<table className="wrapper_table table table-hover table-sm">

				<thead>
					<tr>
						<th className="text fnt_medium">ID Encuesta</th>
						<th className="text fnt_medium min_width">Titulo de encuesta</th>
						<th className="text fnt_medium min_width">No. Encuestados</th>
						<th className="text fnt_medium min_width">Tot. Respuestas</th>
						<th className="text fnt_medium min_width">Fecha límite</th>
						<th className="text fnt_medium">Estatus</th>
						<th className="text fnt_medium">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{surveysData.surveys.map(survey => (
						<tr key={survey.surveyId}>
							<td>
								<Link className="link_data" to={"/details-survey/" + survey.surveyId}>{survey.surveyId}</Link>
							</td>
							<td>
								<Link className="link_data" to={"/details-survey/" + survey.surveyId}>{survey.title}</Link>
							</td>
							<td>
								{survey.surveyedCount}
							</td>
							<td>
								{survey.answeredCount}
							</td>
							<td>
								<Moment locale="es" format="DD/MM/YYYY">
									{survey.endDate}
								</Moment>							
								
							</td>
							<td>{survey.estatus}</td>
							<td>
								<span className="icon_tables" onClick={() => handleDelete(survey)}>
									<i className="far fa-trash-alt"></i>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>

	);
}