import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const useAcknowledgmentLadder = {
  createAcknowledgment,
  updateAcknowledgment,
  deleteAcknowledgment,
  getAcknowledgments
}

async function getAcknowledgments(id) {
  return callApi('/backoffice/CompanyAcknowledgmentLadder/' + id, 'GET').then((result) => {
    if (result.responseCode === '00')
      return result.data
    else {
      swal({ text: result.responseMessage, icon: "error" })
      return null
    }
  })
}

async function createAcknowledgment(params, files) {
  var data = new FormData();
  if (files)
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      data.append('files', f, f.name);
    }
  data.append("model", JSON.stringify(params))

  return callApiFileStorage('/backoffice/CompanyAcknowledgmentLadder', 'POST', data, false)
    .then((result) => {
      if (result.responseCode === '00') {
        swal({ text: "Creación exitosa", icon: "success" })
        return result.data
      }
      else {
        swal({ text: result.responseMessage, icon: "error" })
        return null
      }
    })
}

async function updateAcknowledgment(params, files) {
  var data = new FormData();
  if (files)
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      data.append('files', f, f.name);
    }
  data.append("model", JSON.stringify(params))

  return callApiFileStorage('/backoffice/CompanyAcknowledgmentLadder', 'PUT', data, false)
    .then((result) => {
      if (result.responseCode === '00') {
        swal({ text: "Actualizado correctamente", icon: "success" })
        return result.data
      }
      else {
        swal({ text: result.responseMessage, icon: "error" })
        return null
      }
    })
}

async function deleteAcknowledgment(params) {
  return callApi('/backoffice/CompanyAcknowledgmentLadder', 'DELETE', params).then((result) => {
    if (result.responseCode === '00')
      return result
    else {
      swal({ text: result.responseMessage, icon: "error" })
      return result
    }
  })
}