import React, { useState, useEffect } from "react";
import _default from "react-bootstrap/esm/CardGroup";
import { Link } from "react-router-dom";
import { ecommerceService } from "../../services/ecommerce.service";
import "./caringCommunity.scss";

export function ItemCaring(props) {
  const [CommunitiesData, setCommunitiesData] = useState([]);

  useEffect(() => {
    getCommunities();
  }, []);

  async function getCommunities() {
    // useCaringCommunity
    //   .getCommunitiesClient(process.env.REACT_APP_COMPANY)
    //   .then((result) => {
    //     setCommunitiesData(result);
    //   });

    ecommerceService.getProviders(14).then((result) => {
      setCommunitiesData(result);
    });
  }

  return (
    <div className="content_card_audience">
      <h4 className="text-muted text fnt_medium mb-4">
        Fundaciones sin fines de lucro
      </h4>
      <div className="row">
        {CommunitiesData.map((community) => (
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="item_carin_box shadows card">
              <a
                href={community.urlSite}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="mb-0 ttls text fnt_bold text-uppercase">
                  {community.name}
                </h5>
              </a>
              <Link to={"/caring-community/" + community.id}>
                <div className="box_content_img">
                  <img src={community.providerLogo} alt={community.name} />
                </div>
              </Link>
              <Link
                to={"/caring-community/" + community.id}
                className="text-description"
              >
                {community.info}{" "}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
