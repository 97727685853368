import React, { useEffect } from "react";
import { cartHook } from "../../../hooks/cart.hook";
import { Link } from "react-router-dom";

import SuccessImg from "../../../assets/imgs/ico_ticket.svg";

export function CashPayment() {
  useEffect(() => {
    cartHook.clean();
  });

  return (
    <div className="wrapper_pay_successful">
      <div className="container">
        <div className="row align-items-center justify-content-md-center">
          <div className="col-md-6 text-center">
            <div className="box_success">
              <div className="">
                <span>
                  <img src={SuccessImg} alt="EXITO" />{" "}
                </span>
                <h2 className="text fnt_bold mt-4">Gracias</h2>
                <h5 className>
                  {" "}
                  Te hemos enviado un email, con las instrucciones para que
                  realices tu pago.
                </h5>
              </div>
              <div className="btn_actions_details mt-5">
                <Link className="btn secundary ml-1 mr-1" to="/home">
                  Regresar al inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
