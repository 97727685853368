import React, { useState, useEffect } from "react";
import { directoryInfoService } from "../../services/directoryService.service";
import { categoryServiceService } from "../../services/categoryService.service";
import { ItemSocial } from "./itemSocial";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ambassador.scss";

export function Ambassador() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };

  const trigger = undefined;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [directoryInfo, setDirectoryInfo] = useState([]);
  const [directoryInfoToAuthorize, setdirectoryInfoToAuthorize] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [profile, setProfileInfo] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    selectLocation: "",
    selectCategory: 0,
    selectSubCategory: 0,
    txtFilter: "",
  });
  const [filteredDirectory, setFilteredDirectory] = useState([]);
  
  const getProfileInfo = () => {
    let id = localStorage["userId"];
    setProfileInfo(id);
  };

  const handleSelectFilter = (event) => {
    if ([event.target.name] == "selectCategory") {
      if (event.target.value != "0") {
        categoryServiceService
          .getSubCategoriesFilterByCategory(
            event.target.value,
            process.env.REACT_APP_COMPANY
          )
          .then((subs) => {
            setSubCategoryFilter(subs);
          });
      } else {
        setSubCategoryFilter([]);
      }
    }
    setSelectedFilter({
      ...selectedFilter,
      [event.target.name]: event.target.value,
    });
  };
  const getInitialValues = (roleName) => {
    directoryInfoService
      .getAllByCompany(process.env.REACT_APP_COMPANY)
      .then((data) => {
        if (data != null) {
          setDirectoryInfo(data);
          setFilteredDirectory(data);
          const locations = [...new Set(data.map((data) => data.location))];
          setLocationFilter(locations);
          categoryServiceService
            .getCategoryCatalogFilterByCompany(process.env.REACT_APP_COMPANY)
            .then((categories) => {
              if (categories != null) {
                setCategoryFilter(categories);
              }
            });
        }
      })
      .catch((message) => {});
  };

  useEffect(() => {
    getProfileInfo()
  }, []);


  const updateFilterResult = (directoryInfo) => {
    let result = directoryInfo;
    if (selectedFilter.selectLocation) {
      setIsFiltered(true);
      result = directoryInfo.filter((directory) => {
        return directory.location == selectedFilter.selectLocation;
      });
    }
    if (
      selectedFilter.selectCategory != 0 &&
      selectedFilter.selectCategory != "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return directory.categoryServiceId == selectedFilter.selectCategory;
      });
    }

    if (
      selectedFilter.selectSubCategory != 0 &&
      selectedFilter.selectSubCategory != "0"
    ) {
      setIsFiltered(true);
      result = result.filter((directory) => {
        return (
          directory.subCategoryServiceId == selectedFilter.selectSubCategory
        );
      });
    }

    if (selectedFilter.txtFilter.length >= 2) {
      let word = selectedFilter.txtFilter
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setIsFiltered(true);
      
      result = result.filter((directory) => {
        return (
          directory.fullName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.location
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.subCategoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          directory.categoryServiceName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word) ||
          (directory.directoryContacts.filter((x) => x.contactTypeId == 1)
            .length > 0
            ? directory.directoryContacts
                .filter((x) => x.contactTypeId == 1)[0]
                .contactValue.toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(word)
            : false) || //Telefono
          (directory.directoryContacts.filter((x) => x.contactTypeId == 2)
            .length > 0
            ? directory.directoryContacts
                .filter((x) => x.contactTypeId == 2)[0]
                .contactValue.toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(word)
            : false)
        ); //Email
      });
    }

    setFilteredDirectory(result);
  };

  

  const cleanFilter = () => {
    setIsFiltered(false);
    setSelectedFilter({
      selectLocation: "",
      selectCategory: 0,
      selectSubCategory: 0,
      txtFilter: "",
    });
  };

  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Representantes Regionales</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">¡Acercamos tu comunidad a tu ciudad!</span>
          </div>
        </h4>
      </div>
      {sectionOn ? (
        <div className="box_filter_messg">
          <div className=" box_ordering">
            <div>
            <p className="drawer_reg_sex w-100 mt-2">
              Si tienes un evento, idea, grupo social o alguna comunidad que necesite ayuda, por favor envía un correo al representante de tu región.
            </p>
            </div>
          </div>


        </div>
      ) : (
        ""
      )}
      <div className="row">
    <article className="col-lg-3 col-sm-6 mb-4">
      <div className="card shadows">
        <div className="box_content_img">
        <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/Ambassador.jpeg" />
        </div>
        <div className="card-content text-center">
          <h6 className="card-title mb-0 text fnt_medium name_directory">
            Eduardo Bárcenas<br></br>
            Occidente
          </h6> 
          <br></br>
          <div className="box_data">
              <h6 className="h_line text fnt_medium ">
                <span>DATOS DE CONTACTO</span>
              </h6>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                  <a
                    className="alinks text-break text-left"
                    href={`mailto:eduardob@pridemx.com`}
                  >
                    eduardob@pridemx.com
                  </a>

              </div>
              <hr />
              <div className="wrapper_social_links">
                      <ItemSocial
                        socialIcoName="Instagram"
                        socialIco="logo-instagram"
                        socialUrl="https://www.instagram.com/eduardo.barcenas.186/?hl=es"
                      />
                      <ItemSocial
                        socialIcoName="Facebook"
                        socialIco="logo-facebook"
                        socialUrl="https://www.facebook.com/eduardo.barcenas.186"
                      />
              </div>
            </div>

          </div>
        </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/Ambassador2.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                  Daniel Fernández<br></br>
                  Centro Sur
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:danielf@pridemx.com`}
                      >
                        danielf@pridemx.com
                      </a>

                  </div>
                      
                  <hr />
                  <div className="wrapper_social_links">
                        <ItemSocial
                              socialIcoName="Instagram"
                              socialIco="logo-instagram"
                              socialUrl="https://www.instagram.com/daniel_l_fernandez/?hl=es"
                            />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/daniellopezfernandez26"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
      </div>
    </section>
  );
}
