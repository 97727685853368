import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GeneralUpload } from "../components/Helpers/generalUpload";
import { SelectUsers } from "../components/Helpers/selectUsers";
import { messageService } from "./message.service";
import { EditorMessage } from "../pages/messages/editorMessage";
import { MessageAttachment } from "../pages/messages/messageAttachment";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { callApi, callApiFileStorage } from './service'

export const welcomeMessage = {
    sendMessage
}

const welcomeText = `
<div><p style="text-align:left;">
<img style="max-width:550px; width:100%" src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/Imgs/HeaderBienvenida.png" alt="undefined" style="height: auto;width: auto">
<p></p>
<p><span style="color: rgb(147,101,184);"><em>Autor: Liz Vargas y Daniella Martínez<br></em></span></p>
<p style="text-align:justify;">Las mujeres en México representan el 40.1% de la población económicamente activa, por ese motivo</p>
<p style="text-align:justify;">CONCANACO SERVYTUR, a través de la Vicepresidencia de Mujeres Empresarias, desarrolló la plataforma Soy</p>
<p style="text-align:justify;">Empresaria con la finalidad de que, como nuestro slogan lo dice, estemos "Unidas para accionar a las mujeres</p>
<p style="text-align:justify;">y sus empresas".</br></p>
<p style="text-align:justify;">Esta plataforma la desarrollamos pensando en las mujeres y para apoyarnos entre nosotras, nos ayudará a</p>
<p style="text-align:justify;">generar networking con mujeres de todo México, compartir un canal de música que nos inspire en nuestra</p>
<p style="text-align:justify;">productividad diaria y de podcasts especializados para nosotras. Asimismo, beneficios como descuentos y</p>
<p style="text-align:justify;">promociones en algunos negocios, artículos, consejos y páginas que nos ayuden a crecer nuestro negocio y,</p>
<p style="text-align:justify;">por supuesto, también a nivel personal.</br></p>
<p style="text-align:justify;">¡Inscríbete! Y suma a más colegas para que juntas creemos una red que impulse a las mujeres empresarias y,</p>
<p style="text-align:justify;">de este modo, podamos también hacer crecer nuestro país. </p>

<img style="max-width:550px; width:100%" src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/Imgs/MensajeBienvenida.jpeg" alt="undefined" style="height: auto;width: auto">
</div>
`

const messageData = {
    "subject": "¡Te doy la bienvenida a la comunidad!",
    "messageText": ""
}

async function sendMessage(user) {
    let usersTo = [user];
    messageData.messageText = welcomeText;
    let data = { message: messageData, usersTo: usersTo };
    messageService.createWelcomeMessage(data, false).then((data) => {
        if (data != false) {
            console.log('creado ', data);
        } 
    });
}
