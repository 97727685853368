import React from "react";
import { Link } from "react-router-dom";
import "./boxStories.scss";

export function BoxStories(props) {
  return (
    <div className="wrapper_box_stories">
      <h5 className="mb-2 text fnt_medium ttls_in_stories">
        Boletines TLC
      </h5>

      <div className="section_item_stories">
        <div className="box_img_stories mb-2">
          <img src={props.urlImg} alt="Boletines TLC" />
        </div>
        <div className="box_info_stories">
          <h5 className="text mb-1 fnt_medium">{props.tituloPost}</h5>
          <div className="more">
            <Link to={props.linkURL}>
              <span>Ver más</span>{" "}
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
