import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";

export const companyPostService = {
  create,
  update,
  getAll,
  getAllMinimalistPosts,
  getCompanyPostById,
  deletet,
  getPostUsers,
  getCompanyPostFilesById,
  getCompanyPostImagesById,
  getAllFront,
  getCompanyPostImagesByIdFront,
  getCompanyPostImagesPathByIdFront,
  getCompanyPostImagesPathById,
  getLastCompanyPostFront,
  getLatest,
  getAllPaginated
};

async function create(params, images, files) {
  var data = new FormData();
  if (images) {
    for (var i = 0; i < images.length; i++) {
      var f = images[i];
      data.append("images", f, f.name);
    }
  }

  if (files) {
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      data.append("files", f, f.name);
    }
  }
  data.append("model", JSON.stringify(params));

  return callApiFileStorage("/backoffice/CompanyPost", "POST", data).then(
    (result) => {
      return handleRsponse(result);
    }
  );
}

async function update(params, images, files) {
  var data = new FormData();
  if (images) {
    for (var i = 0; i < images.length; i++) {
      var f = images[i];
      data.append("images", f, f.name);
    }
  }

  if (files) {
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      data.append("files", f, f.name);
    }
  }
  data.append("model", JSON.stringify(params));

  return callApiFileStorage("/backoffice/CompanyPost", "PUT", data).then(
    (result) => {
      return handleRsponse(result);
    }
  );
}

async function getCompanyPostById(postType, companyPostId) {
  return callApi(`/backoffice/CompanyPostById/${postType.toString()}/${companyPostId}`, "GET").then(
    (result) => {
      if (result.responseCode == "00") {
        return result.data;
      } else {
        swal({ text: result.responseMessage, icon: "error" });
        return null;
      }
    }
  );
}

async function getAllMinimalistPosts(postType) {
  return callApi("/backoffice/CompanyPost-Min/" + postType.toString(), "GET").then(
    (result) => {
      if (result.responseCode == "00") {
        return result.data;
      } else {
        swal({ text: result.responseMessage, icon: "error" });
        return null;
      }
    }
  );
}

async function getAll(postType) {
  return callApi("/backoffice/CompanyPost/" + postType.toString(), "GET").then(
    (result) => {
      if (result.responseCode == "00") {
        return result.data;
      } else {
        swal({ text: result.responseMessage, icon: "error" });
        return null;
      }
    }
  );
}
async function getAllFront(postType, dateFilter) {
  return callApi(
    "/backend/CompanyPost/" + postType.toString() + "/" + dateFilter,
    "GET"
  ).then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" });
      return null;
    }
  });
}
async function getCompanyPostImagesByIdFront(companyPostId) {
  return callApi(
    "/backend/CompanyPost/WithImages/" + companyPostId,
    "GET"
  ).then((result) => handleRsponse(result));
}

async function getCompanyPostFilesById(companyPostId) {
  return callApi(
    "/backoffice/CompanyPost/WithFiles/" + companyPostId,
    "GET"
  ).then((result) => handleRsponse(result));
}

async function getCompanyPostImagesById(companyPostId) {
  return callApi(
    "/backoffice/CompanyPost/WithImages/" + companyPostId,
    "GET"
  ).then((result) => handleRsponse(result));
}
async function getCompanyPostImagesPathById(companyPostId) {
  return callApi(
    "/backoffice/CompanyPost/WithImagesPath/" + companyPostId,
    "GET"
  ).then((result) => handleRsponse(result));
}

async function getCompanyPostImagesPathByIdFront(companyPostId) {
  return callApi(
    "/backend/CompanyPost/WithImagesPath/" + companyPostId,
    "GET"
  ).then((result) => handleRsponse(result));
}
async function getLastCompanyPostFront(postCategoryId) {
  return callApi("/backend/CompanyPost/LastOne/" + postCategoryId, "GET").then(
    (result) => handleRsponse(result)
  );
}

async function getLatest(postCategoryId) {
  return callApi("/backend/companypost/latest/" + postCategoryId, "GET").then(
    (result) => handleRsponse(result)
  );
}

async function getPostUsers(params) {
  return callApi(
    "/backoffice/CompanyPost/getPostUsers/" +
      params.DepartmentId +
      "/" +
      params.JobRoleId,
    "GET"
  ).then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" });
      return null;
    }
  });
}

async function deletet(params) {
  return callApi("/backoffice/CompanyPost", "DELETE", params);
}

function handleRsponse(result) {
  if (result.responseCode === "00") return result.data;
  else {
    //swal({ text: result.responseMessage, icon: "error" })
    return null;
  }
}
async function getAllPaginated(postType, PageIndex, PageTotal) {
  return callApi("/backoffice/CompanyPost-Paginated/" + postType.toString() + '/' + PageIndex  + '/' + PageTotal, "GET").then(
    (result) => {
      if (result.responseCode == "00") {
        return result.data;
      } else {
        swal({ text: result.responseMessage, icon: "error" });
        return null;
      }
    }
  );
}