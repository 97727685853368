import React, { Fragment, useState, useEffect }  from "react";
import PictureCourse from "../../assets/imgs/bg-section.jpg";
import './advertisingSection.scss'
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import { Link } from "react-router-dom";

export function AdvertisingSection({HolType}) {
  useEffect(() => {
    getCompanyHolidays();
  }, []);

  const [sliderItems, setSliderItems] = useState([]);
  const [banner, setBanner] = useState([]);
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [holyday, setHolyday] = useState({ type: 0, src: "" })

  async function getCompanyHolidays() {
    if(HolType){
      useCompanyHoliday.getCompanyHolidayFront(HolType).then((result) => {
          if (result) {
              var temp = [];
              result.map((ch) => {
                  if(holyday.type != 6){
                    temp.push({
                        key: ch.companyHolidayId.toString(),
                        file: ch.imagePath,
                        description: ch.description,
                        isVideo: ch.isVideo,
                        link: ch.iframe
                    });
                    setDefaultBanner(false)
                  }
              });
              setSliderItems(temp);
              const bannerItem = temp.sort(() => Math.random() - Math.random())
                                       .find(() => true);
              setBanner(bannerItem)
          }
      });
    }
  }

  return (
      <div className="wrapper_bg_in_section">
        {
          defaultBanner?
            HolType == 0 &&
            <img src={PictureCourse} alt="name" />
          :
            <a target="_blank" href={ banner.link } className="item_advertising" key={banner.key}>
              <img src={banner.file} alt={banner.description} />
            </a>
        }
        
      </div>
  );
}
