import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const useCaringCommunity = {
    createCommunity,
    updateCommunity,
    deleteCommunity,
    getCommunities,
    getCommunityById,
    getCommunitiesClient,
};

async function getCommunities(companyId) {
    return callApi('/backoffice/GetCaringCommunity?companyId='+ companyId, 'GET').then((result) => handleRsponse(result));
}
async function getCommunityById(communityId) {
    return callApi('/backoffice/GetCaringCommunityById/' + communityId, 'GET').then((result) => handleRsponse(result));
}
async function createCommunity(params) {
    return callApiFileStorage('/backoffice/InsertCaringCommunity', 'POST', params, false).then((result) => handleRsponse(result));
}
async function updateCommunity(params) {
    return callApiFileStorage('/backoffice/CaringCommunity', 'PUT', params, false).then((result) => handleRsponse(result));
}
async function deleteCommunity(CaringCommunityId) {
    return callApi('/backoffice/DeleteCaringCommunity/' + CaringCommunityId, 'DELETE').then((result) => handleRsponse(result));
} 
function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        swal({ text: result.responseMessage, icon: "error" })
        return null;
    }
}
async function getCommunitiesClient(companyId) {
    return callApi('/backend/GetCaringCommunity?companyId='+ companyId, 'GET').then((result) => handleRsponse(result));
}
