import React from "react";

export function ProfileChat(props) {
    return (
        <div className="sections_chat active_sec wrapper_profile_chat p-3"
        >
            <div
                className="sec_avatar_profile"
                style={{
                    backgroundImage:
                        'url("https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/1/Imgs/Profile/perfil_Mesa de trabajo 1.jpg")',
                }}
            >
                <span className="to_back">
                    <i className="fas fa-chevron-left"></i>
                </span>
                <span className="to_edit_avatar">
                    <i className="fas fa-pen"></i>
                </span>

                <div className="content_info_profile">
                    <h5 className="text fnt_medium m-0">Laura Mertinez Carmona</h5>
                    <div className="dropdown drawer_drop_status">
                        <span
                            className=" dropdown-toggle"
                            id="dropdownMenuStatus"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="dots_status active"></span>
                            <span>Activo</span>
                        </span>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuStatus">
                            <button className="dropdown-item" type="button">
                                <span className="dots_status active"></span>
                                <span>Activo</span>
                            </button>
                            <div className="dropdown-divider"></div>

                            <button className="dropdown-item " type="button">
                                <span className="dots_status occupied"></span>
                                <span>Ocupado</span>
                            </button>
                            <div className="dropdown-divider"></div>

                            <button className="dropdown-item" type="button">
                                <span className="dots_status disconnected"></span>
                                <span>Desconectado</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sec_info_profile">
                <div className="mb-3">
                    <h6>
                        <span>
                            <i className="far fa-envelope"></i>
                        </span>{" "}
            Correo electrónico
          </h6>
                    <h5 className="text fnt_medium">lmartinez@miempresa.com.mx</h5>
                </div>
                <div className="mb-3">
                    <h6>
                        <span>
                            <i className="far fa-building"></i>
                        </span>{" "}
            Puesto
          </h6>
                    <h5 className="text fnt_medium">Recursos Humanos</h5>
                </div>
                <div className="mb-3">
                    <h6>
                        <span>
                            <i className="fas fa-birthday-cake"></i>
                        </span>{" "}
            Cumpleaños
          </h6>
                    <h5 className="text fnt_medium">12 Diciembre 1990</h5>
                </div>
            </div>
        </div>
    );
}
