import React from "react";

export function AvatarChats({ img, titleImg, flagClass }) {
  return (
    <div className="wrapper__bg_flags">
      {flagClass && flagClass ? (
        <div className={`bgflag ${flagClass}`}></div>
      ) : (
        ""
      )}

      <div className="contain_avatar">
        <div className="box_avatar">
          <img
            src={
              img
                ? img
                : "https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/BaseImage/Default-avatar.jpg"
            }
            alt="Usuarios en Kuimby"
            title={titleImg}
          />
        </div>
      </div>
    </div>
  );
}
{
  /* ====================
     CLASES COLOR STATUS
     ======================
    .active
    .occupied
    .disconnected */
}
{
  /*<span className="status_color active"></span>*/
}
