import React from "react";
import Moment from "react-moment";
import "moment/locale/es";

export function ItemConversation(props) {
  return (
    <div className={`box_item_conversation ${props.source}`}>
      <div className="item_conversation">
        <div className="cont_conversation">
          <div className="info_mnj">
            <span className="author_mnj">{props.author}</span>
            <span className="box_check_mnj">
              {props.source === "out" ? (
                <span className="check_mnj">
                  {!props.checkMnj ? (
                    <ion-icon name="chatbubble-outline"></ion-icon>
                  ) : (
                    <ion-icon name="chatbubble"></ion-icon>
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <p className="mnj">{props.chatMnj}</p>
        </div>
        <div className="datatime_mnj">
          <Moment format="ddd D/MM/YY hh:mm a">{props.dateTime}</Moment>
        </div>
      </div>
    </div>
  );
}
