import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';

import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

export function EditProductFilter(props) {
    const dispatch = useDispatch();

    const onSave = () => {
        inputs.BranchOfficeId = parseInt(inputs.BranchOfficeId);
        inputs.DepartmentId = parseInt(inputs.DepartmentId);
        inputs.JobRoleId = parseInt(inputs.JobRoleId);
        inputs.RewardProductId = parseInt(inputs.RewardProductId);
        inputs.Points = parseInt(inputs.Points)
        inputs.RewardCount = parseInt(inputs.RewardCount)
        inputs.ParentStock = parseInt(inputs.ParentStock)
        if (inputs.RewardProductFilterId && inputs.RewardProductFilterId > 0)
            productFilterService.edit(inputs);
        else
            productFilterService.create(inputs);
    };

    const [showEdit, setShowEdit] = useState(false);
    const [minCouponCount, setMinCouponCount] = useState(0);


    const [stock, setStock] = useState(0);
    const [catalogs, setCatalog] = useState({

        branchOfficeCatalog: [],
        departmentCatalog: [],
        jobRoleCatalog: [],
        productCatalog: []

    });

    const [productFilter, setProductFilter] = useState({
        RewardProductFilterId: 0,
        CompanyId: 0,
        RewardProductId: null,
        BranchOfficeId: 0,
        DepartmentId: 0,
        JobRoleId: null,
        Points: 0,
        Active: null,
        RewardCount: 0,
        FilterStock: 0
    });
    useEffect(() => {
        productFilterService.getCatalogs().then((result) => {
            setCatalog({
                branchOfficeCatalog: result.branchOffice,
                departmentCatalog: result.department,
                jobRoleCatalog: result.jobRole,
                productCatalog: result.rewardProduct
            })
        });
    }, []);
    useEffect(() => {
            if (props.rewardProductFilterId != 0) {
                setShowEdit(true);
                productFilterService.getById(props.rewardProductFilterId).then((result) => {
                    setMinCouponCount(result.filterStock * -1);
                    setProductFilter({
                        RewardProductFilterId : result.rewardProductFilterId,
                        CompanyId: result.companyId,
                        RewardProductId: result.rewardProductId,
                        BranchOfficeId: result.branchOfficeId,
                        DepartmentId: result.departmentId,
                        JobRoleId: result.jobRoleId,
                        Points: result.points,
                        Active: result.active,
                        RewardCount: result.rewardCount,
                        FilterStock: result.filterStock,
                        ParentStock: result.parentStock,
                        
                    });
                });
            }
            else  {
                setMinCouponCount(1);
                setShowEdit(false);
            }
    }, [props.rewardProductFilterId]);
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, productFilter);

    const onChange = (e) => {
        handleInputChange(e);
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('stock');
        inputs.ParentStock = option;
        //setStock(option);
    }

    return (
        <div className="wrapper_form_reward card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.rewardProductFilterId !== 0 ? "Editar filtro" : "Nuevo filtro"}</legend>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="branchOfficeId">Sucursal</label>
                                <select className="form-control" id="branchOfficeId" name="BranchOfficeId" value={inputs.BranchOfficeId} onChange={handleInputChange}>
                                    <option>Todas</option>
                                    {
                                        catalogs.branchOfficeCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="departmentId">Grupo</label>
                                <select className="form-control" id="departmentId" name="DepartmentId" value={inputs.DepartmentId} onChange={handleInputChange} >
                                    <option>Todos</option>
                                    {
                                        catalogs.departmentCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="jobRoleId">Categoría</label>
                                <select className="form-control" id="jobRoleId" name="JobRoleId" value={inputs.JobRoleId} onChange={handleInputChange} >
                                    <option>Todos</option>
                                    {
                                        catalogs.jobRoleCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="rewardProductId">Producto</label>
                                <select className="form-control" id="rewardProductId" name="RewardProductId" value={ inputs.RewardProductId } onChange={onChange} required  disabled = {showEdit} >
                                <option value="">Seleccione </option>
                                    {
                                        catalogs.productCatalog.map(item => <option key={item.value} value={item.value} stock={item.stock} > {item.label + ' | Existencia en bolsa: ' + item.stock}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {showEdit ?  <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="FilterStock">Stock del filtro</label>
                                <input type="number" name="FilterStock" className="form-control" onChange={handleInputChange} value={inputs.FilterStock} required disabled />
                            </div>
                    </div> : null   }
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="RewardCount">Cant. Cupones</label>
                                <input type="number" name="RewardCount" className="form-control" onChange={handleInputChange} min={minCouponCount} max={inputs.ParentStock} value={inputs.RewardCount} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="points">Puntos</label>
                                <input type="number" name="Points" className="form-control" onChange={handleInputChange} min="0" value={inputs.Points} required />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/product-filter" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   