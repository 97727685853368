import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const useCompanyHoliday = {
    createCompanyHoliday,
    updateCompanyHoliday,
    deleteCompanyHoliday,
    getCompanyHolidays,
    getCompanyHolidayById,
    getCompanyHolidayWithFilesById,
    getCompanyHolidayFront
};
async function getCompanyHolidays() {
    return callApi('/backoffice/CompanyHoliday', 'GET').then((result) => handleRsponse(result));
}
async function getCompanyHolidayById(compayHolidayId) {
    return callApi('/backoffice/CompanyHoliday/' + compayHolidayId, 'GET').then((result) => handleRsponse(result));
}
async function getCompanyHolidayWithFilesById(compayHolidayId) {
    return callApi('/backoffice/CompanyHoliday/WithFiles/' + compayHolidayId, 'GET').then((result) => handleRsponse(result));
}
async function getCompanyHolidayFront(typeId) {
    return callApi('/backend/CompanyHoliday/filtered/' + typeId, 'GET').then((result) => handleRsponse(result));
}
async function createCompanyHoliday(params, files) {
    var data = new FormData();
    if (files)
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/CompanyHoliday', 'POST', data,false).then((result) => {
        return handleRsponse(result);
    });
}
async function updateCompanyHoliday(params, files) {
    var data = new FormData();
    if (files)
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    data.append("model", JSON.stringify(params));
    return callApiFileStorage('/backoffice/CompanyHoliday', 'PUT', data, false).then((result) => handleRsponse(result));
}
async function deleteCompanyHoliday(params) {
    return callApi('/backoffice/CompanyHoliday', 'DELETE', params).then((result) => handleRsponse(result));
}
function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        swal({ text: result.responseMessage, icon: "error" })
        return null;
    }
}