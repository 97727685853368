import { callApi } from './service'

export const useSurveyUserProfile = {
    createSurveyUserProfile,
    completeSurveyUserProfile,
    deleteSurveyUserProfile,
    getSurveysUserProfile
};
async function createSurveyUserProfile(params) {
    return callApi('/backend/SurveyUserProfile', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getSurveysUserProfile() {
    return callApi('/backend/SurveyUserProfile', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function completeSurveyUserProfile(params) {
    return callApi('/backend/SurveyUserProfile', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteSurveyUserProfile(params) {
    return callApi('/backend/SurveyUserProfile', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}