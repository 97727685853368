import React, { useEffect, useState } from "react";
import { useSurveyUserProfileAnswer } from "../../services/surveyUserProfileAnswer.service";
import swal from "sweetalert";

export function AnswersSurvey({
  index,
  question,
  surveyUserProfileId,
  next,
  setQuestionIndexSave,
  questionIndexSave,
  setLastSave,
}) {
  const [userAnswer, setUserAnswer] = useState({});
  const [answerData, setAnswersData] = useState([]);
  const [SelectedAnswerId, setSelectedAnswerId] = useState("");
  const [SelectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    getAnswers(question);
  }, [question]);

  useEffect(() => {
    if (questionIndexSave == index) handleSubmit();
  }, [questionIndexSave]);

  const getAnswers = async (question) => {
    useSurveyUserProfileAnswer
      .getSurveysUserProfileAnswersByQuestionId(
        question.surveyQuestionId,
        surveyUserProfileId
      )
      .then((result) => {
        if (result) {
          setAnswersData(result.answers);
          if (result.selectedAnswerId) {
            setSelectedAnswerId(result.selectedAnswerId);
            var ua = {
              SurveyUserProfileAnswerId: result.surveyUserProfileAnswerId,
              SurveyUserProfileId: surveyUserProfileId,
              SurveyQuestionId: question.surveyQuestionId,
              SelectedAnswerConfigId: result.selectedAnswerId,
              Value: result.answerValue,
            };
            setUserAnswer(ua);
          }
          if (result.answerValue) {
            setSelectedValue(result.answerValue);
            setLastSave(index);
          }
        }
      });
  };

  const handleTextChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
    setSelectedValue(event.target.value);
  };
  const handleOptionChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
  };

  const handleSubmit = () => {
    if (userAnswer.userAnswer?.surveyUserProfileAnswerId ?? null) {
      var upd = {
        SurveyUserProfileAnswerId:
          userAnswer.userAnswer.surveyUserProfileAnswerId,
        SurveyUserProfileId: surveyUserProfileId,
        SurveyQuestionId: userAnswer.userAnswer.surveyQuestionId,
        SelectedAnswerConfigId: SelectedAnswerId,
        Value: SelectedValue,
      };
      setUserAnswer(upd);
      useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result);
              next();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      var ins = {
        SurveyUserProfileId: surveyUserProfileId,
        SurveyQuestionId: question.surveyQuestionId,
        SelectedAnswerConfigId: SelectedAnswerId,
        Value: SelectedValue,
      };
      setUserAnswer(ins);
      useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result);
              next();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  return (
    <div className="box_resp">
      {answerData.map((answer) => (
        <div key={answer.surveyAnswerConfigId}>
          {
            {
              1: (
                <div className="form-group p-1">
                  <input
                    type="text"
                    value={SelectedValue}
                    onChange={(event) => handleTextChange(event, answer)}
                    className="form-control"
                  />
                </div>
              ),

              2: (
                <div className=" custom-control custom-radio ml-4 mb-3 text-left">
                  <input
                    type="radio"
                    name={"Q" + question.surveyQuestionId}
                    id={"Q" + answer.surveyAnswerConfigId}
                    value={answer.surveyAnswerConfigId}
                    checked={SelectedAnswerId === answer.surveyAnswerConfigId}
                    onChange={(event) => handleOptionChange(event, answer)}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"Q" + answer.surveyAnswerConfigId}
                  >
                    {answer.isImage ? (
                      <img
                        className="opt_img_answer"
                        src={answer.answer}
                        alt="Tu opinion en kuimby"
                      />
                    ) : (
                      answer.answer
                    )}
                  </label>
                </div>
              ),

              3: (
                <div className="custom-control custom-radio ml-4 mb-3 text-left">
                  <input
                    type="radio"
                    name={"Q" + question.surveyQuestionId}
                    id={"Q" + answer.surveyAnswerConfigId}
                    value={answer.surveyAnswerConfigId}
                    checked={SelectedAnswerId === answer.surveyAnswerConfigId}
                    onChange={(event) => handleOptionChange(event, answer)}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"Q" + answer.surveyAnswerConfigId}
                  >
                    {answer.answer}
                  </label>
                </div>
              ),

              4: (
                <div className="form-group p-1">
                  <input
                    type="text"
                    value={SelectedValue}
                    onChange={(event) => handleTextChange(event, answer)}
                    className="form-control"
                  />
                </div>
              ),
            }[question.surveyAnswerTypeId.toString()]
          }
        </div>
      ))}
    </div>
  );
}
