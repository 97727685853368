import React, { Fragment, useEffect, useState } from 'react';
import { useAcknowledgment } from '../../services/acknowledgment.service'
import Moment from 'react-moment'

export default function TextBoard(props) {
  const [acknowledgements, setAcknowledgements] = useState([]);

  useEffect(() => {
    useAcknowledgment.getAcknowledgmentsType()
      .then((data) => setAcknowledgements(data))
  }, [])

  return (
    <div className="col-sm-5 col-lg-4 mb-4">
      <div className="shadows content_text_board">
        <div>
          <p className="mb-4 pt-1 pl-2 pr-2 line_height"><small>Siempre es bueno reconocer las cualidades especiales de las personas, cualidades que los caracterizan y que suman al equipo.</small></p>
          <h5 className="text fnt_medium mb-2 pl-2 pr-2">Categorías disponibles</h5>
          {acknowledgements.map((acknowledgement, index) =>
            <Fragment key={index}>
              <div className="item_cat_board" onClick={() => props.setIsActive(acknowledgement)}>
                <div
                  onClick={props.MyFunBoard}
                >
                  <h5 className="mb-0 text fnt_medium">&#8226; {acknowledgement.title}</h5>
                  <p className="mb-0 line_height"><small>{acknowledgement.descritption}</small></p>
                  <p className="mb-0"><small>Vence el <Moment locale="es" format="DD [de] MMMM  YYYY">{acknowledgement.validTo}</Moment></small></p>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}