import { callApi, callApiNoBlocking } from "./service";

export const courseViewService = {
  getCourse,
  getFileById,
  completeCourse,
  getCourseComplete,
  GetUserProfileCourse,
  getLatestCourse,
};

async function getCourse() {
  return callApi("/backend/Course", "GET").then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      alert(result.responseMessage);
      return null;
    }
  });
}

async function getCourseComplete() {
  return callApi("/backend/Course/GetAllCompleted", "GET").then((result) =>
    handleResponse(result)
  );
}

async function GetUserProfileCourse() {
  return callApi("/backend/Course/GetUserProfileCourse", "GET").then((result) =>
    handleResponse(result)
  );
}

async function getLatestCourse() {
  return callApiNoBlocking("/backend/Course/Latest", "GET").then((result) =>
    handleResponse(result)
  );
}

async function getFileById(entityId, entityTypeId, sequence) {
  return callApi(
    "/backoffice/Storage/WithFile/" +
      entityId +
      "/" +
      entityTypeId +
      "/" +
      sequence,
    "GET"
  ).then((result) => handleResponse(result));
}

async function completeCourse(courseId) {
  return callApi("/backend/course/complete", "POST", {
    CourseId: courseId,
  }).then((result) => {
    return handleResponse(result);
  });
}

function handleResponse(result) {
  if (result.responseCode === "00") return result.data;
  else {
    //alert(result.responseMessage);
    return null;
  }
}
