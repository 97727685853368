import React, { useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";

export function ListContact({ showChat }) {
  const [contacts, setContacts] = useState([]);
  const [blockedContacts, setBlockedContacts] = useState([]);
  const [contactsSearch, setContactsSearch] = useState([]);
  let currentInitial = "";

  useEffect(() => {
    chatService.getContacts().then((result) => {
      const activeContacts = result.activeContacts.filter(
        (item) => !result.blockedContacts.includes(item.userId)
      );
      const blockedContacts = result.activeContacts.filter((item) =>
        result.blockedContacts.includes(item.userId)
      );
      setContacts(activeContacts);
      setContactsSearch(activeContacts);
      setBlockedContacts(blockedContacts);
    });
  }, []);

  const handleSearch = (text) => {
    if (text) {
      text = text.toUpperCase();
      let contactFiltered = contacts.filter((item) =>
        item.name.removeAccents().toUpperCase().includes(text)
      );

      if (contactFiltered.length > 0) {
        let contactsFiltered = Object.assign([], contactFiltered);
        setContactsSearch(contactsFiltered);
        return;
      }

      setContactsSearch([]);
    } else setContactsSearch(contacts);
  };

  const handleOpenChat = (user) => {
    const chat = {
      contactId: user.userId,
      name: user.name,
      image: user.urlImage,
      identityFlagName: user.flag,
      readOnly: false,
    };
    showChat(chat);
  };

  const [openBlocked, setOpenBlocked] = useState("");
  const handleOpenBlocked = () => {
    setOpenBlocked(openBlocked === "" ? "open_sec_blocked" : "");
  };

  return (
    <div className="sections_chat active_sec wrapper_contacts list_users p-3">
      <div className="list_users__options">
        <TtlsChats title="Contactos" />
        <button className="btn-new-group" onClick={handleOpenBlocked}>
          <ion-icon name="close-circle-outline"></ion-icon>{" "}
          <span>Bloqueados</span>
        </button>
      </div>

      <Searches placeHolder="Buscar contacto" handleSearch={handleSearch} />

      <div className="overflow_wpr">
        <div className="content_contacts">
          {contactsSearch.map((contact) => {
            let initial = "";
            const removeAccents = contact.name.substring(0, 1).removeAccents();

            if (currentInitial != removeAccents) {
              currentInitial = removeAccents;
              initial = removeAccents;
            }

            return (
              <>
                {initial && (
                  <div className="letter_divider">
                    <span>{initial}</span>
                  </div>
                )}
                <div className="box_item_chat " key={contact.userId}>
                  <AvatarChats
                    img={contact.urlImage}
                    flagClass={contact.flag}
                  />
                  <div className="info_item_chat">
                    <div className="name_chat text fnt_book">
                      {contact.name}
                    </div>
                    <div className="options">
                      <span
                        className="opt_chat"
                        onClick={() => handleOpenChat(contact)}
                      >
                        <i className="fas fa-comment-dots"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className={`sec_list_user_blocked ${openBlocked}`}>
        <span className="toback" onClick={handleOpenBlocked}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <h6 className="text fnt_medium mb-4 pl-4">Usuarios bloqueados</h6>
        <div className="overflow_wpr">
          {blockedContacts.map((contact) => (
            <div className="box_item_chat " key={contact.userId}>
              <AvatarChats img={contact.urlImage} flagClass={contact.flag} />
              <div className="info_item_chat">
                <div className="name_chat text fnt_book">{contact.name}</div>
                <div className="options">
                  <span
                    className="opt_chat"
                    onClick={() => handleOpenChat(contact)}
                  >
                    <i className="fas fa-comment-dots"></i>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
