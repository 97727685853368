import { useState } from 'react';

const useInputSingleForm = (callback, initialValues) => {
    const [inputs, setInputs] = useState(initialValues);

    const handleSubmit = (event) => {
        if (event)
            event.preventDefault();
        callback();
    }
    const handleInputChange = (event) => {
        if (event.persist !== undefined) event.persist();
        if (event.target.type === 'checkbox') {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.checked }));
        } else {
            setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
        }
    }

    return {
        handleSubmit,
        handleInputChange,
        inputs,
        setInputs
    };
}
export default useInputSingleForm;
