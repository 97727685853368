import React from 'react'
import './providerImage.scss'

const ProviderImage = ({ urlImageVideo }) => {
  return (
    <div className="box_provider_image">
          <img src={urlImageVideo} alt="Proveedor" />
    </div>
  )
}

export default ProviderImage;