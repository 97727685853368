import React, { useState } from 'react';
import { SearchCompany } from './searchCompany';

import './companyAdmin.scss'
import { Link } from 'react-router-dom';




export function AdminCompany() {
    
    const [statusSelected, setStatus] = useState(true)
    const [refresh, setRefresh] = useState(0);
    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    }
    return (
        <section className="wrapper_company_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Empresas</h2>
                        <h6 className="mb-0 text-muted">Operaciones</h6>
                    </div>
                    <div className="form-group">
                                <label for="filterStatus">Estado</label>
                                <select className="form-control" id="statusId" name="rvolCompany" onChange={handleSelectChange} >
                                <option value="true" selected>Activo</option> 
                                <option value="false">Inactivo</option>
                                </select>
                            </div>
                    <div className="box_actions">
                        <Link className="btn primary" to={"/details-company/0"}><i className="fas fa-plus"></i> Agregar Empresa</Link>
                    </div>
                </div>
            </div>
            <div>
                <SearchCompany statusValue={statusSelected} />
            </div>

        </section>
    );
}