import React, { useEffect, useState } from 'react';
import { useSurveyAnswerType } from '../../services/surveyAnswerType.service';
import { EditAnswerType } from '../surveyAdmin/editAnswerType'

export function SearchSurveyAnswerType(props) {
	const [surveysData, setSurveysData] = useState({ surveys: [] });
	const handleDelete = (data) => {
		useSurveyAnswerType.deleteSurveyAnswerType(data).then((result) => { getSurveyAnswerTypes(); });
	};

	useEffect(() => {
		getSurveyAnswerTypes();
	}, [props.refresh]);

	async function getSurveyAnswerTypes() {
		useSurveyAnswerType.getSurveyAnswerTypes().then((result) => {
			if (result) {
				setSurveysData({ surveys: result });
			}
		});
	}

	return (
		<div className="wrapper_type_answer">
			<div className="row">
				<div className="col-md-6 mb-3">
					<EditAnswerType />
				</div>
				<div className="col-md-6 mb-3">
					<h4 className=" mb-4">Lista de tipos de respuestas</h4>
					<div className="table-responsive">
						<table className="wrapper_table table table-sm table-hover">
							<thead>
								<tr>
									<th className="text fnt_medium">Id	</th>
									<th className="text fnt_medium min_width">Description</th>
									<th className="text fnt_medium">Active		</th>
									<th className="text fnt_medium">Acciones		</th>
								</tr>
							</thead>
							<tbody>
								{surveysData.surveys.map(survey => (
									<tr key={survey.surveyAnswerTypeId} >
										<td>{survey.surveyAnswerTypeId}</td>
										<td>{survey.description}</td>
										<td>{survey.active ? 'Active' : 'Inactive'}</td>
										<td>
											<span className="icon_tables" onClick={() => props.onSelectAnswerType(survey)}>
												<i className="fas fa-pen"></i>
											</span>
											<span className="icon_tables" onClick={() => handleDelete(survey)}>
												<i className="far fa-trash-alt"></i>
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>

	);
}