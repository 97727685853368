import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { actionTypeService } from '../../services/actionType.service';

const SearchActionType = forwardRef((props, ref) => {

	const [dataGrid, setDataGrid] = useState([]);
	const handleDelete = (event, data) => {
		actionTypeService.deleteActionType(data).then((result) => { getActionTypes(); });
		event.stopPropagation();
	};

	async function getActionTypes() {
		actionTypeService.getActionType()
			.then((result) => {
				if (result) {
					setDataGrid(result);
				}
			});
	}

	useEffect(() => {
		getActionTypes();
	}, []);

	const refreshDataGrid = () => {
		getActionTypes();
	}

	useImperativeHandle(ref, () => ({
		refreshDataGrid: refreshDataGrid
	}));

	return (

		<div className="table-responsive">
			<table className="wrapper_table table table-sm table-hover ">
				<thead>
					<tr>
						<th className="text fnt_medium min_width">Descripción</th>
						<th className="text fnt_medium">Operador</th>
						<th className="text fnt_medium">Status</th>
						<th className="text fnt_medium">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{dataGrid.map(item => (
						<tr key={item.actionTypeId} >
							<td><span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.description}</span> </td>
							<td>{item.operator}</td>
							<td>{item.active ? 'Active' : 'Inactive'}</td>
							<td>
								<span className="icon_tables" onClick={() => props.handleRowSelect(item)}>
									<i className="fas fa-pen"></i>
								</span>
								<span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
									<i className="far fa-trash-alt"></i>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>

		</div>
	);
});

export default SearchActionType;
