import { callApi } from './service'
import swal from "sweetalert"

export const contactInfoService = {
    createContact
};
async function createContact(params) {
    return callApi('/Site/HelpContact', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Gracias por comunicarte con nosotros, en breve un ejecutivo se pondrá en contacto.", icon: "success" });
            return true
        } else {
            swal({ text: "Ocurrió un error al enviar la información.", icon: "error" });
            return false
        }
    });
}
