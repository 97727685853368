import React, { useState } from 'react';
import { ContentState, convertToRaw,EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function EditorCommentsBox(props) {

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  async function save(e) {
    e.preventDefault();  
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    if (props.id) {
      props.clickSave(value, props.id);
    } else {
      props.clickSave(value);
    }    
    setEditorState(EditorState.createEmpty());
  }

  return (
    <form onSubmit={save}>
      <div className="contain_write_comments">
        <div className="wrapper__bg_flags">
          <div className={"bgflag " + (props.profileUser ? props.profileUser.genderIdentityFlag : "")}></div>
          <div className="contain_avatar">
            <div className="box_avatar">
              <img src={props.profileUser ? props.profileUser.urlProfileImage : ""} alt="PMX" />
            </div>
          </div>
        </div>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName=" box--comments"
          editorClassName="editor-box-comments"
          toolbarClassName="toolbar-box-comments"
          toolbar={{
            options: ["emoji"],
          }}
        />
        <button type="submit" className="btn_send"><i className="fas fa-paper-plane"></i></button>
      </div>
    </form>
  );
}
