import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { companyPostService } from '../../services/companyPost.service';
import { backendService } from "../../services/backend.service";
import Moment from 'react-moment'
import {Pagination} from "rsuite";

const SearchCompanyPost = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const [originValues, setOriginValues] = useState({
        currentWriter: ""
    });
    const trigger = undefined;
    const [pageIndex, setPageIndex] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handleDelete = (event, data) => {
        companyPostService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        companyPostService.getAllMinimalistPosts(0)
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }
    async function getAllPaginated(){
        companyPostService.getAllPaginated(0,pageIndex,15).then((data) =>{
            if (data){
                setDataGrid(data.list)
                setTotalPages(data.totalPages)
            }
            // setTotalMessage(data.totalCount);
            // setSentMessageId(sentMessageId.concat(data.list));
          });
    }

    const handleSelectPage = (eventKey) => setPageIndex(eventKey)

    useEffect(() => {
        getAllPaginated();
    }, [pageIndex]);

    const refreshDataGrid = () => getAll();

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    useEffect(() => {
        getProfileInfo();
    }, [trigger]);

    const getProfileInfo = () => {
        backendService.userProfile().then((data) => {
            setOriginValues({
                currentWriter: data.firstName + ' ' + data.middleName + ' ' + data.lastName ?? ""
            });
        });
    };

    return (
        <>
            <div className="table-responsive">
                <table className="wrapper_table table table-hover table-sm">
                    <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Título del artículo</th>
                        <th className="text fnt_medium min_width">Fecha de publicación</th>
                        {/* <th className="text fnt_medium text-center">Vistas</th> */}
                        {/*<th className="text fnt_medium">Autor</th>*/}
                        <th className="text fnt_medium">Estatus</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyPostId} >
                            <td>
                                {
                                    originValues.currentWriter == item.writter ?
                                        <span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.title}</span>
                                        :
                                        <span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.title}</span>
                                }
                            </td>
                            <td><Moment format="DD/MM/YYYY">{item.publishDate}</Moment></td>
                            {/* <td className="text-center">{item.totalViews}</td> */}
                            <td>{item.active ? 'Guardada' : 'Inactivo'}</td>
                            {item.active ?
                                <td>
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td> : null}
                        </tr>
                    ))}

                    </tbody>
                </table>
                <div className={'table-pagination'}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        size="md"
                        pages={totalPages}
                        activePage={pageIndex}
                        onSelect={handleSelectPage}
                    />
                </div>
            </div>
        </>
    );

});

export default SearchCompanyPost;
