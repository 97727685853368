import React from "react";

export function Rating({ min, max, onChange, value, edit }) {
  const range = (min, max) =>
  Array(max - min + 1).fill().map((_, i) => min + i)

  const RatingItem  = ({ checked, colored, onChange, value }) => (
    <label className={`rating__item ${colored ? 'rating__item--selected' : ''}`}>
      <input
        checked={checked}
        className='rating__input'
        onChange={(e) => onChange(value)}
        type="radio"
        value={value}
      />
    </label>
  )
  const RatingDone  = ({ checked, colored }) => (
    <label className={`rating__done ${colored ? 'rating__itemdone--selected' : ''}`}>
      <input
        checked={checked}
        className='rating__input'
        type="radio"
        value={value}
        disabled={true}
      />
    </label>
  )

  return (
    <div className='rating'>
      {
        range(min, max).map(item => (
          edit ?
            <RatingItem
              colored={value >= item}
              checked={value === item}
              value={item}
              onChange={onChange}
            />
          :
            <RatingDone
              colored={value >= item}
              checked={value === item} 
            />
              
        ))
      }
    </div>
  )
}
