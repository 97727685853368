import { callApi } from './service'
import swal from "sweetalert"

export const useAcknowledgment = {
    createAcknowledgment,
    updateAcknowledgment,
    getAcknowledgments,
    getAcknowledgment,
    getAcknowledgmentsType,
    getAcknowledgmentUserProfile,
    createAcknowledgmentUserProfile,
    getUserProfileDestinataries,
    getAcknowledgmentUserProfileTop1,
    getUserProfileTop10,
    getAcknowledgmentUserModule
};

async function getAcknowledgments() {
    return callApi('/backoffice/AcknowledgmentType', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function getAcknowledgment(id) {
    return callApi('/backoffice/AcknowledgmentType/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function createAcknowledgment(params) {
    return callApi('/backoffice/AcknowledgmentType', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Creación exitosa", icon: "success" });
            return result.data
        }
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function updateAcknowledgment(params) {
    return callApi('/backoffice/AcknowledgmentType', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Actualizado correctamente", icon: "success" });
            return result.data
        }
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function getAcknowledgmentsType() {
    return callApi('/backend/AcknowledgmentType', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function getAcknowledgmentUserProfile() {
    return callApi('/backend/AcknowledgmentUserProfile', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return []
        }
    });
}

async function getAcknowledgmentUserModule() {
    return callApi('/backend/GetAcknowledgmentUserProfileModule', 'GET').then((result) => {
        if (result.responseCode === '00')
        {
            return result.data
        }
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function createAcknowledgmentUserProfile(params) {
    return callApi('/backend/AcknowledgmentUserProfile', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se ha generado el reconocimiento", icon: "success" });
        }
        if (result.responseCode === '99') {
            swal({ text: "No se pudo realizar el reconocimiento", icon: "error" })
        }
        if (result.responseCode === '03') {
            swal({ text: "No es posible realizar un Reconocimiento a sí mismo", icon: "error" })
        }
        return result.data
    });
}

async function getUserProfileDestinataries() {
    return callApi('/backend/AcknowledgmentUserProfile/destinataries', 'GET').then((result) => {

        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function getAcknowledgmentUserProfileTop1() {
    return callApi('/backend/AcknowledgmentUserProfile/top1', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return []
        }
    });
}

async function getUserProfileTop10(id) {
    return callApi('/backend/AcknowledgmentUserProfile/top10/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}