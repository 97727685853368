import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./itemInfoPost.scss";

import agendaEvent from "../../assets/imgs/agendaEvent.png";
import AvatarUser from "../../assets/imgs/avatar_profile.jpg";

export function ItemInfoPost(props) {
  return (
    <div>
      {props && props.currentEvent ? (
        <Link to="/diary" className="item_blackboard ico_calendar">
          <div className="box_avatar_user ">
            <div className="avatar">
              {props.currentEvent.iconPath ? (
                <img src={props.currentEvent.iconPath} alt="NameUser" />
              ) : (
                <img src={agendaEvent} alt="NameUser" />
              )}
            </div>
          </div>
          <div className="info_post">
            <h6 className="text fnt_medium mb-0">{props.currentEvent.name}</h6>
            <h6 className="mb-0">{props.currentEvent.location}</h6>
            <h6 className="mb-0 text-muted">
              {props.currentEvent.allDay
                ? moment(new Date(props.currentEvent.startDate)).format(
                    "DD/MM/yyyy"
                  ) + " Todo el día"
                : moment(new Date(props.currentEvent.startDate)).format(
                    "DD/MM/yyyy"
                  ) +
                  " De " +
                  moment(new Date(props.currentEvent.startDate)).format(
                    "HH:mm"
                  ) +
                  " a " +
                  moment(new Date(props.currentEvent.endDate)).format("HH:mm")}
            </h6>
          </div>
        </Link>
      ) : (
        <Link to="/" className="item_blackboard">
          <div className="box_avatar_user">
            <div className="avatar">
              <img src={AvatarUser} alt="NameUser" />
            </div>
          </div>
          <div className="info_post">
            <h6 className="text fnt_medium mb-0">Valores 2020</h6>
            <h6 className="mb-0">Viviana Cortéz</h6>
            <h6 className="mb-0 text-muted">14 Reconocimientos</h6>
          </div>
        </Link>
      )}
    </div>
  );
}
