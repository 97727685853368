import React from 'react';
import './itemPurchaseDetails.scss'
import { useSelector } from 'react-redux';

const ItemPurchaseDetails = (props) => {
    const countrySetting = useSelector(state => state.countrySetting);

    return (
        <div className="box_item_purchase_details trans">
            <div >
                <span className="btn_delete_item" onClick={() => props.deleteItemDetail(props.ProviderId, props.ProductId)} ><i className="far fa-trash-alt"></i></span>
            </div>
            <div className="w-100 item_details">
                <p>{props.Name}</p>
                <p className="price_unit">Precio individual {countrySetting.currency + (props.Price).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</p>
            </div>
            <div className="box_catidad">
                <select className="custom-select custom-select-sm" onChange={(e) => props.onChangeQuantity(props.ProviderId, props.ProductId, e.target.value)} value={props.Quantity}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <div className="box_item_total">{countrySetting.currency}{(props.Total).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</div>
        </div>
    )
}

export default ItemPurchaseDetails