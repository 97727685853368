import { callApi, callApiNoBlocking, callApiFileStorage } from "./service";
import swal from "sweetalert";
import { post } from "jquery";

export const postulanteService = {
  agregarPostulante,
  getPostulants,
  getCV,
  GetByUserId,
  verifyPostulant,
  approvePostulant,
  rejectPostulant,
};

  async function agregarPostulante(data) {
    return callApiFileStorage("/BackEnd/Postulant", "POST", data, false)
      .then((result) => {
        return result;
      });
  }

  async function getPostulants(id) {
    return callApi("/BackEnd/Postulant/GetByJobId"+ id, "GET")
      .then((result) => {
        return result.data;
      });
  }

  async function getCV(id) {
    return callApi("/BackEnd/Postulant/GetCurriculum"+ id, "GET")
      .then((result) => {
        console.log(result);
        return result.data;
      });
  }

  async function GetByUserId(userId) {
    return callApi("/BackEnd/Postulant/GetByUserId?userId="+userId, "GET")
      .then((result) => {
        return result.data;
      });
  }

  async function verifyPostulant(jobId) {
    return callApi("/BackEnd/Postulant/VerifyPostulant?JobId="+jobId, "GET")
      .then((result) => {
        return result.data;
      });
  }

  async function approvePostulant(postulantId, companyName){
    return callApiNoBlocking("/BackEnd/Postulant/" + postulantId + "/Approve?companyName="+companyName, "POST")
      .then((result) => {
        return result.data;
      });
  }

  async function rejectPostulant(postulantId, companyName){
    return callApiNoBlocking("/BackEnd/Postulant/" + postulantId + "/Reject?companyName="+companyName, "POST")
      .then((result) => {
        return result.data;
      });
  }

