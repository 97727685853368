import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useQuestion } from '../../services/surveyQuestion.service'
import { SelectCustom } from '../../components/Helpers/SelectCustom'

export function EditQuestion(props) {
	const onSave = () => {
		if (inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0)
			useQuestion.updateQuestion({ SurveyQuestionId: parseInt(inputs.SurveyQuestionId), SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
		else
			useQuestion.createQuestion({ SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
	};
	const handleOnCancel = () => {
		if (props.onCancelEdit)
			props.onCancelEdit();
	};
	const [question, setQuestion] = useState({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "" });
	const [refreshAnswers, setRefreshAnswers] = useState(0);
	const [showEditAnswer, setShowEditAnswer] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState();
	const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, question);

	useEffect(() => {
		let newP = {};
		if (props.currentQuestion) {
			newP = { SurveyQuestionId: props.currentQuestion.SurveyQuestionId.toString(), SurveyId: props.currentQuestion.SurveyId.toString(), SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId.toString(), Question: props.currentQuestion.Question };
			setQuestion(newP);
		}
		else
			setQuestion({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "" });
		setRefreshAnswers(prev => prev + 1);
		setSelectedAnswer(null);
		setShowEditAnswer(false);
	}, [props.currentQuestion]);

	return (
		<div className="content_new_question">
			<form onSubmit={handleSubmit}>
				<div className="row">
					<input type="hidden" name="SurveyQuestionId" onChange={handleInputChange} value={inputs.SurveyQuestionId} />
					<input type="hidden" name="SurveyId" onChange={handleInputChange} value={inputs.SurveyId} />
					<div className="col-12">
						<div className="form-group">
							<label for="">Pregunta</label>
							<input type="text" className="form-control" name="Question" onChange={handleInputChange} value={inputs.Question} required />
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label for="">Tipo de respuesta</label>
							<SelectCustom method="SurveyAnswerType" textField="description" valueField="surveyAnswerTypeId" selectName="SurveyAnswerTypeId" handleOnChange={handleInputChange} selectValue={inputs.SurveyAnswerTypeId} />
						</div>
					</div>
				</div>
				<div className="mt-2 text-right">
					<button className="btn secundary minimum ml-1 mr-1" type="button" onClick={handleOnCancel}>Cancelar</button>
					<button className="btn primary minimum ml-1 mr-1" type="submit">Guardar</button>
				</div>
			</form>
		</div>

	);
}   