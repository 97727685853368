import React, { useState, useEffect } from "react";
import { ItemSocial } from "./itemSocial";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./prideMusic.scss";

export function PrideMusic() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Podcast</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-music"></i>
            </span>
            <span className="pl-2 pr-2">Música y programas para nosotros</span>
          </div>
        </h4>
      </div>
        <div className="box_filter_messg">
          <div className=" box_ordering">
            <div>
            <p className="drawer_reg_sex w-100 mt-2">
            ¿Conoces algún podcast o programa que ayude a más personas a comprender más sobre el comercio exterior? Notifícalo a TLC Administrador.
            </p>
            </div>
          </div>


        </div>

      <div className="row">
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/Music/DATO%20PEQUE.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Dato Peque del Trueque
              </h5> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/show/60jH99lCEzK5B2oLOxTOGe?si=827e8ba5325b42db`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          {/* <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          /> */}
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/Music/DATO%20WEEKLY.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                DATO WEEKLY
              </h5> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/show/5Ajabr9v4HiCEvn5VKwFJH?si=ab0e94a299e14947`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          {/* <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          /> */}
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/Music/HUT.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Hagamos Un Trueque
              </h5> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/show/4U4T9PxGcNxCC7dInWxonr?si=9dc1edc438f54f3b`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          {/* <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          /> */}
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/1089/Music/HUTEM.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Hagamos Un Trueque Entre Mujeres
              </h5> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/show/23vE2F82NyEgXwcecjLrL2?si=8535d7738b574a89`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          {/* <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          /> */}
                  </div>
                </div>
              </div>
            </div>
        </article>
        
      </div>
    </section>
  );
}
