import React, { Fragment, useState } from "react";
import { attendeeService } from "../../services/attendee.service";
import { SelectUsers } from "./selectUsers";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import "rsuite/dist/styles/rsuite-default.css";
import { useEffect } from "react";

export function AudienceUsers({ targetId, eventType, selectUser }) {
  const [users, setUsers] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [show, setShow] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [usersRemove, setUsersRemove] = useState([]);

  useEffect(() => {
    setShow(false);
    getUsers();
  }, [targetId, eventType]);

  const getUsers = () => {
    attendeeService.getAttendeeUsers(targetId, eventType).then((result) => {
      if (result) {
        setUsers(result);
        selectUser(result);
      } else {
        setUsers([]);
        selectUser([]);
      }
    });
  };

  const addUsers = () => {
    setUsers([...users, ...userSelec]);
    selectUser([...users, ...userSelec]);
    closeModal();
  };

  const removeUsers = () => {
    setUsers(users.filter((item) => !usersRemove.includes(item.id)));
    selectUser(users.filter((item) => !usersRemove.includes(item.id)));
    setUsersRemove([]);
  };

  const closeModal = () => {
    setShowSelect(false);
    setUserSelec([]);
  };

  const handleSelectUser = (event, data) => {
    data.check = event.target.checked;
    if (data.check) setUsersRemove([...usersRemove, data.id]);
    else setUsersRemove(usersRemove.filter((item) => item != data.id));
  };

  const handleSelectUserAll = (event) => {
    let localUsers = [];
    users.map((data) => {
      if (event.target.checked && !data.check) {
        usersRemove.push(data.id);
        localUsers = usersRemove;
      }

      data.check = event.target.checked;
    });

    setUsersRemove(localUsers.length == 0 ? [] : [...localUsers]);
  };

  return (
    <div>
      <button
        type="button"
        className="btn secundary minimum"
        onClick={() => setShow(!show)}
      >
        {show ? "Ocultar audiencia" : "Ver audiencia"}
      </button>
      {show && (
        <Fragment>
          <Modal
            isOpen={showSelect}
            size="lg"
            scrollable={true}
            style={{ height: "100%" }}
          >
            <ModalBody>
              <div className="row align-items-center">
                <div className="col-md">
                  <div className="form-group">
                    <SelectUsers
                      selectUser={setUserSelec}
                      title="Agregar usuarios:"
                      usersFilter={users}
                      filter={true}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn secundary minimum"
                onClick={closeModal}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn primary minimum"
                onClick={addUsers}
              >
                Agregar
              </button>
            </ModalFooter>
          </Modal>

          <div className="drawer_btns_sect">
            <h4>Audiencia</h4>
            <div>
              <button
                type="button"
                className="btn secundary minimum  mr-1 mb-1"
                onClick={() => setShowSelect(true)}
              >
                <i className="fas fa-search"></i>{" "}
                <span className="label_btn">Buscar usuarios</span>
              </button>
              {usersRemove.length > 0 && (
                <button
                  type="button"
                  className="btn secundary minimum  mb-1 "
                  onClick={removeUsers}
                >
                  <i className="far fa-trash-alt"></i>{" "}
                  <span className="label_btn">Eliminar usuarios</span>
                </button>
              )}
            </div>
          </div>

          <div>
            <br />
            <table className="wrapper_table table table-striped table-hover table-sm">
              <tr>
                <th>Nombre</th>
                <th>Grupo</th>
                <th>
                  {users.length > 0 ? (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkboxAllAtenndee"
                        className="custom-control-input"
                        onClick={handleSelectUserAll}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkboxAllAtenndee"
                      >
                        Seleccionar
                      </label>
                    </div>
                  ) : (
                    "Seleccionar"
                  )}
                </th>
              </tr>
              {users && (
                <Fragment>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td className="td_user">{user.name}</td>
                      <td className="td_user">{user.department}</td>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`check-a-${user.id}`}
                            onClick={(event) => handleSelectUser(event, user)}
                            checked={user.check}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`check-a-${user.id}`}
                          >
                            {" "}
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              )}
            </table>
          </div>
        </Fragment>
      )}
    </div>
  );
}
